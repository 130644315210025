import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useRef } from 'react';

function Toast(props) {
  const [show, setShow] = useState(false);
  const toastRef =  useRef(null)
  const toast = window.$(toastRef.current);
  useEffect(() => {
    setShow(true);
    if (props.show) {
      setShow(true);
    toast.toast('show');
      const timeout = setTimeout(() => {
        toast.toast('hide');
        setShow(false);
        props.onHide();
      }, 3000); // hide the toast after 3 seconds
      return () => clearTimeout(timeout);
    }
  }, [props]);

  const className = `toast ${props.type === 'success' ? 'bg-success' : 'bg-danger'} text-white`;

  return (
    <div  style={{position:'relative', zIndex: 1057}} className={`${show ? 'visible' : 'invisible'}`} >
      <div className={className} role="alert" aria-live="polite" aria-atomic="true" data-delay="10000" ref={toastRef} style={{position: 'fixed', top: 15, right: 15}}>
        <button type="button" className="btn btn-icon btn-transparent btn-lg" onClick={() => setShow(false)} style={{position: 'absolute',top: 0, right: 0, padding: '0.5rem'}}>
          <FontAwesomeIcon icon={faClose} size="xl" color='white'/>
        </button>
        <div className="toast-body">{props.message}</div>
      </div>
    </div>
  );
}

export default Toast;