import React, { useContext, useEffect, useState } from 'react';

export const ToastContext = React.createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('');

  const showToastMessage = (message, type) => {
    setShowToast(true);
    setToastMessage(message);
    setToastType(type);
  };

  const hideToast = () => {
    setShowToast(false);
  };

  useEffect(() => {
    let toastTimer;
    if (showToast) {
      toastTimer = setTimeout(() => {
        setShowToast(false);
      }, 3000); // Adjust the duration as needed
    }

    return () => {
      clearTimeout(toastTimer);
    };
  }, [showToast]);

  const value = {
    showToast,
    toastMessage,
    toastType,
    showToastMessage,
    hideToast,
  };

  return (
    <ToastContext.Provider value={value}>
      {children}
    </ToastContext.Provider>
  );
};