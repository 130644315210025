import React, { useEffect, useState } from 'react'
import Offers from '../../components/Offers';
import { CallApi } from '../../utils';
import Modal from '../../components/Modal';
import AddEditDiscount from '../../components/FormModals/AddEditDiscount';
import AddEditNotes from '../../components/FormModals/AddEditNotes';
import ConfirmModal from '../../components/ConfirmModal';
import { useToast } from '../../redux/toastContext';
import AddEditProductModal from '../../components/AddEditProductModal';

const PackageOffers = (props) => {
    const [searchText, setSearchText] = useState('');
    const [cards, setCards] = useState([]);
    const [selectedCards, setSelectedCards] = useState([]);
    const [subTotal, setSubTotal] = useState(0);
    const [discountType, setDiscountType] = useState('');
    const [discountValue, setDiscountValue] = useState('');
    const [discountedPrice, setDiscountedPrice] = useState(0);
    const [vat, setVat] = useState(0);
    const [total, setTotal] = useState(0);
    const [notes, setNotes] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showProductModal, setShowProductModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [singleProduct, setSingleProduct] = useState('');
    const [modalFor, setModalFor] = useState('');
    const [packageData, setPackageData] = useState([]);
    const [confirmModalData, setConfirmModalData] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const { showToastMessage } = useToast();
    const [nextAvailableOrder, setNextAvaialableOrder] = useState(0);
    let initialized = false
    useEffect(() => {
        if (!initialized) { 
            initialized = true
            getCards(searchText);
            getPackage(props.data.id);
            // getOrder();
        }
    }, [searchText,props.data]);

    // Function to get products list
    const getCards = async (search) => {
        const params = search ? `packages/cards?pacakgeId=${props.data.id}&search=${search}`: `packages/cards?pacakgeId=${props.data.id}`;
        
        try {
            const responseData = await CallApi('GET','',params);
            if(responseData.data && responseData.data.length > 0 ){
                const maxOrder = Math.max(...responseData.data.map((item) => item.card_order), 0);
                setNextAvaialableOrder(maxOrder + 1);
                setCards(responseData.data.filter(item => item.in_package === 0));
                if(!search){
                    setSelectedCards(responseData.data.filter(item => item.in_package === 1))
                }
            }
        } catch (error) {
            
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');
        }
    }

    // function to get package information
    const getPackage = async (id) => {
        const params = `packages?id=${id}`;
        
        try {
            const responseData = await CallApi('GET','',params);
            if(responseData.status){
                const packageData = responseData.data;
                setPackageData(packageData);
                setSubTotal(parseFloat(packageData.subtotal))
                setDiscountType(packageData.discount_type)
                setDiscountValue(packageData.discount_value ? parseFloat(packageData.discount_value) : null)
                let discountedPrice = packageData.discount_type == "0" ? parseFloat(packageData.subtotal) * parseFloat(packageData.discount_value)/100 : parseFloat(packageData.discount_value);
                setDiscountedPrice(discountedPrice);
                setVat(parseFloat(packageData.vat))
                setTotal(parseFloat(packageData.total))
                if(packageData.note){
                    setNotes(packageData.note)
                }else{
                    setNotes('')
                }
            }
        } catch (error) {
            
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');
        }
    }

    const handleProductSelect = async (clickedCard) => {
        let params = `packages/addCard`;
            const formData = new FormData();
            formData.append('package_id', props.data.id);
            formData.append('card_id', clickedCard.card_id);
            formData.append('card_price', clickedCard.card_price);
            if(discountValue){
                formData.append('discount_type', discountType);
                formData.append('discount_value', discountValue);
            }
            if(notes){
                formData.append('notes', notes);
            }
            try {
                const responseData = await CallApi('POST','', params, formData);
                if(responseData.status){
                getCards(searchText);  
                getPackage(props.data.id)
                }else{
                    showToastMessage(responseData.message,'error')
                }
            } catch (error) {
                console.log(error)
                showToastMessage('Something went wrong. Action not performed.','error');
            }
    }

    const handleProductDeSelect = async (clickedCard) => { 
        let params = `packages/removeCard`;
        const formData = new FormData();
        formData.append('package_id', props.data.id);
        formData.append('card_id', clickedCard.card_id);
        formData.append('card_price', clickedCard.card_price);
        try {
            const responseData = await CallApi('POST','', params, formData);
            if(responseData.status){
                getCards(searchText);  
                getPackage(props.data.id)
            }
        } catch (error) {
            console.log(error)
            showToastMessage('Something went wrong. Action not performed.','error');
        }
    }

    // Function to get single product details
    const getProduct = async (id) => {
        try {
            const params = `products/?id=${id}`;
            const responseData = await CallApi('GET',showToastMessage,params);
            setSingleProduct(responseData.data);
            setShowProductModal(true);                                                                                                                     
        } catch (error) {
            
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');
        }
    }

    const createPackage = async () => { 
        let params = `packages?id=${props.data.id}`;
        const formData = new FormData();
        formData.append('isCompleted', 1);
        try {
            const responseData = await CallApi('POST','', params, formData);
            if(responseData.status){
                showToastMessage('Package Created','success');
                props.onAddPackage()
            }
        } catch (error) {
            console.log(error)
            showToastMessage('Something went wrong. Action not performed.','error');
        }
    }

    // function to add/edit discount
    const addDiscount = async (openModal) => {
        setModalFor("discount")
        if(openModal && discountValue){
            setShowModal(true);
            setModalTitle("Edit Discount");
        }else if(openModal){
            setShowModal(true);
            setModalTitle("Add Discount");
        }
    }

    // Function to handle when add new note added & get refreshed list
    const onAddItem = (data, source) => {
        if(data.data.status){
            getPackage(props.data.id)
        }
    }

    // function to get notes list
    const getNotes = async (openModal) => {
        setModalFor("notes")
        setShowModal(true);
        if(notes){
            setModalTitle("Edit Notes");
        }else{
            setNotes('')
            setModalTitle("Add Notes");
        }
    }

    // Function to handle deleting product
    const handleDeleteProduct =  (id) => {
        setShowConfirmModal(true);
        const confirmData = {
            "title":"Confirm Delete",
            "text": "Are you sure you want to delete this card?",
            "id": id,
            "type":"product"
        }
        setConfirmModalData(confirmData);
    }
    
    const handleDeleteNote = () => { 
        setShowConfirmModal(true);
        const confirmData = {
            "title":"Confirm Delete",
            "text": "Are you sure you want to delete this Note?",
            "id": props.data.id,
            "type":"note"
        }
        setConfirmModalData(confirmData);
    }

    // Function to confirm any action being performed
    const confirmModalAction = (data) => {
        if(data.type == "product"){
            deleteProduct(data.id)
        }
        if(data.type == 'note'){
            deleteNote(data.id)
        }
        setShowConfirmModal(false);
        setConfirmModalData('');
    }

     // Function to delete single product
     const deleteProduct = async (id) => {
        try {
            const params = `products/?id=${id}`;
            const responseData = await CallApi('DELETE',showToastMessage,params);
            if(responseData.status){
                getCards();
                showToastMessage(`Card deleted.`,'success');                      
            }else{
                showToastMessage("Card not deleted","error")
            }
                                                                                                    
        } catch (error) {
            
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');
        }
    }

     // Function to delete single note
     const deleteNote = async (id) => {
        try {
            const params = `notes/?id=${id}&mod=package`;
            const responseData = await CallApi('DELETE','',params);
            if(responseData.status){
                getCards(searchText);  
                getPackage(props.data.id)
                showToastMessage(`Note Deleted.`,'success');      
            }else{
                showToastMessage("Note not deleted",'error')
            }                                                                                    
        } catch (error) {
            
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');                      
        }
    }

     // Function to hide modal
     const handleShowModal = () => {
        getCards();
        setSingleProduct('');
        setShowProductModal(true);
    };

    // Function to handle add new product
    const handleAddProduct = (data) => {
        if(data.status && (data.message == 'Insert Record' || data.message == 'Update Record')){
            getCards();
            let msgVar = data.message == 'Insert Record' ? 'added' : 'updated';
            showToastMessage(`Product ${msgVar} successfully`,'success');
        }else if(data.message == 'Not added' || data.message == 'Not updated'){
            let msgVar = data.message == 'Insert Record' ? 'added' : 'updated';
            showToastMessage(`Product not ${msgVar}`,'error');
        }else if(data.message == 'Resource not found'){
            showToastMessage(`Requested resource not found on this server`,'error');
        }else{
            showToastMessage(`Somethign went wrong. Please contact to developer.`,'error');
        }
    }

    return (
        <>
        <Offers 
            setSearchText={setSearchText} 
            cards={cards}
            notes={notes}
            selectedCards={selectedCards} 
            handleProductSelect={handleProductSelect} 
            handleDeSelectedProducts={handleProductDeSelect}
            submitOffer={createPackage}
            addDiscount={addDiscount}
            getNotes={getNotes}
            getProduct={getProduct}
            handleDeleteNote={handleDeleteNote}
            handleDeleteProduct={handleDeleteProduct}
            handleShowModal={handleShowModal}
            cancel={props.onAddPackage}
            totalPrice={subTotal}
            discountType={discountType}
            discountValue={discountValue}
            discountedPrice={discountedPrice}
            vat={vat}
            total={total}
            screen="packages"
        />
         {/* Add/Edit Product Modal */}
         <AddEditProductModal
                show={showProductModal}
                onHide={() => setShowProductModal(false)}
                onAddProduct={handleAddProduct}
                product={singleProduct}
                AddEditProductModal={AddEditProductModal}
                nextOrderId={nextAvailableOrder}
            />
        {/* Custom modal In which we can set content from here */}
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            title={modalTitle}
        >
            {modalFor == 'notes' ? 
            // Add / Edit Notes Form in modal
            <AddEditNotes modalShow={showModal} onHide={() => setShowModal(false)} notes={notes} packageId={props.data.id} onAddItem={(data) => onAddItem(data,'note')} module='package'/>
            : // Add / Edit Discount Form in modal
            <AddEditDiscount modalShow={showModal} onHide={() => setShowModal(false)} selectedItems={selectedCards} packageId={props.data.id} totalPrice={subTotal} discount={packageData} onAddItem={(data) => onAddItem(data,'discount')} module='package'/>}
        </Modal>
        {/* Confirm modal to confirm action before it perform */}
        <ConfirmModal show={showConfirmModal} data={confirmModalData} onConfirm={(data) => confirmModalAction(data)} setShowConfirmModal={(val) => setShowConfirmModal(val)}/>
        </>
    )
}

export default PackageOffers