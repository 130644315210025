import React, { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom';
import avatar1 from "../../assets/avatar/avatar-1.png";
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout } from '../../redux/auth/Actions';
import { fileUrl } from '../../utils';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';

const UserDropDown = (props) => {
    const authUser = JSON.parse(useSelector(state => state.auth.user));
    const dispatch = useDispatch();
    const handleLogout = () => {        
        dispatch(logout());
    }
    
    return (
        <li className="nav-item dropdown">
            <a
                href="#"
                data-bs-toggle="dropdown"
                className="nav-link dropdown-toggle nav-link-lg nav-link-user"
            >
                <img
                    alt="image"
                    src={authUser.userData.user_image ? fileUrl+authUser.userData.user_image : avatar1}
                    className="rounded-circle me-1"
                />
                <div className="d-sm-none d-lg-inline-block">
                    {authUser && authUser.userData.user_name}
                </div>
            </a>
            <div className="dropdown-menu dropdown-menu-right pt-2">
                <a
                    href="#"
                    className="dropdown-item has-icon text-danger"
                    onClick={() => handleLogout()}
                >
                  <FontAwesomeIcon icon={faSignOut}/> Logout
                </a>
            </div>
        </li>
    )
}

export default UserDropDown