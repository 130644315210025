import React, { useEffect, useRef, useState } from 'react'
import { useToast } from '../redux/toastContext';
import { CallApi } from '../utils';
import Select from 'react-select';
import { faCropSimple } from '@fortawesome/free-solid-svg-icons';

const CompanyDropdown = (props) => {
    const [companies, setCompanies] = useState([]);
    const [respData, setRespData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedCompany,setSelectedCompany] = useState('');
    const [selectedValue,setSelectedValue] = useState('');
    const selectRef = useRef(null);
    const { showToastMessage } = useToast()


    const getCompanies = async() => { 
        setIsLoading(true);
        const params = `companies/getAll${search ? `?search=${search}` : ''}`;
        // Make an API call to fetch paginated data
        try {
            const responseData = await CallApi('GET','',params);
            if(responseData.status){
                setIsLoading(false);
                if(responseData.data.length > 0){
                     // Append the new options to the existing options
                    let data = responseData.data.map((cmp) => ({ value: cmp.id, label: cmp.company_name }));
                    setCompanies((prevOptions) => [...prevOptions, ...data])
                    setRespData(responseData.data);

                    responseData.data.map((cmp) => {
                        if(cmp.id == props.selectedCompany?.company_id){
                            setSelectedValue({value: cmp.id, label: cmp.company_name})
                            setSelectedCompany(cmp);
                        }
                    });
                }
            }else{
                setIsLoading(false);
                // setCompanies([{value: 0, label: "No companys Available"}])
            }
        } catch (error) {
            setIsLoading(false);
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');
        }
    }

    const selectCompany = (data) => { 
        if(data){
            let selectedComp = respData.filter((cmp) => cmp.id === data.value);
            setSelectedCompany(selectedComp[0]);
            if(selectedComp.length > 0){
                setSelectedValue({value: selectedComp[0].id, label: selectedComp[0].company_name})
            }
        }else{
            setSelectedValue('')
        }
    }

    const handleScroll = (e) => { 
        const { target } = e;

        if (
        target.scrollTop + target.offsetHeight === target.scrollHeight &&
        !isLoading && currentPage < totalPages
        ) {
            // Increment the page number for the next fetch
            setCurrentPage((prevPage) => prevPage + 1);
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown') {
            const selectMenu = selectRef.current.menuListRef;
            const isScrolledToBottom =
              selectMenu.scrollTop + selectMenu.offsetHeight ===
              selectMenu.scrollHeight;
      
            if (isScrolledToBottom && !isLoading && currentPage < totalPages) {
                // Increment the page number for the next fetch
                setCurrentPage((prevPage) => prevPage + 1);
            }
        }
    };

    const handleInputChange = (inputValue) => {
        setCompanies([]);
        setCurrentPage(1);
        setSearch(inputValue);
        if(inputValue == ''){
            getCompanies();
        }
    }

    const selectComp = () => { 
        if(selectedCompany){
            selectRef.current.setValue('')        
            props.onHide(selectedCompany);    
        }else{
            showToastMessage('Please select company first to continue','error');
        }
    }

    useEffect(() => {
        if(props.modalShow){
            // setSelectedValue('')
            setCompanies([]);
            getCompanies();
        }
    }, [props.modalShow]);

    useEffect(() => {
        
        if(search != ''){
            getCompanies();
        }
    },[search])

    return (
        <div className='container'>
            <div>
                {companies.map((data, index) => (
                    <div key={index}>{data.company_name}</div>
                ))}
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='company-drpodown py-3 px-3'>
                        <Select 
                            className='basic-single'
                            classNamePrefix="select"
                            defaultValue=""
                            isSearchable={true}
                            name='Company'
                            options={companies}
                            placeholder="Select Company"
                            noOptionsMessage={() => "No Company available"}
                            onChange={selectCompany}
                            isLoading={isLoading}
                            onMenuScrollToBottom={handleScroll}
                            onKeyDown={handleKeyDown}
                            onInputChange={handleInputChange}
                            
                            isClearable={true}
                            ref={selectRef}
                            value={selectedValue}
                        />
                    </div>
                </div>
            </div>
            <div className='modal-footer'>
                <button type="submit" className="btn btn-primary" onClick={selectComp}>
                    Continue
                </button>
                <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={props.onHide}
                >
                    Close
                </button>
            </div>
        </div>
    )
}

export default CompanyDropdown