import React, { useState } from 'react'
import LeadsList from './LeadsList';
import LeadForms from './LeadForms';

const Leads = () => {
    const [content, setContent] = useState('leadslist');
    const [data, setData] = useState('');

    const handleContent = (content, data) => {
        setContent(content);
        setData(data);
    }
    return (
        <div className="main-content">
            <section className="section">
                <div className="section-header">
                    <h1>Leads</h1>
                </div>
                <div className="section-body">
                {content == 'leadslist' ?
                    <LeadsList addUser={(data) => handleContent('userform',data)}/>
                    : <LeadForms onAddUser={(data) => handleContent('leadslist',data)} data={data}/>
                }
                </div>
            </section>
        </div>
    )
}

export default Leads