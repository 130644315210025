import React, { useEffect, useRef, useState } from 'react'
import { CallApi } from '../../utils';
import { useSelector } from 'react-redux';
import { useToast } from '../../redux/toastContext';

const AddEditPackages = (props) => {
    const [formData, setFormData] = useState({
        packageName:'',
        packageDesc:''
    })
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const authUser = JSON.parse(useSelector(state => state.auth.user));
    const { showToastMessage } = useToast()

    // set formdata data when input changed
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    // Reset form data
    const resetForm = () => {
        setFormData({
            packageName: '',
            packageDesc: ''
        });
    }

    // Handle Submitted form data
    const handleSubmit = async(e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        // check form validity
        if (form.checkValidity() === false) {
            // add form validator class to validate form
            form.classList.add("was-validated");
        }else{

            //check if notes props present then update note else create note
            let params =  Array.isArray(props.package) ? 'packages' : `packages?id=${props.package.id}` ;

            const fd = new FormData();
            for (let key in formData) {
                if (formData.hasOwnProperty(key)) {
                    fd.append(key, formData[key]);
                }
            }
            if(!props.package){
                fd.append('created_by', authUser.userData.id);
            }
            // fd.append('lead_id',props.leadId)
            // Process form submission
            setIsSubmitting(true);
            // submit form data to server using API with post method
            try {
                const responseData = await CallApi('POST','', params, fd);
                if(responseData.status){
                    // reset form data values
                    resetForm();
                    // hide modal
                    props.onHide();
                     // pass error to onAddProduct() on home page
                    if(Array.isArray(props.package)){
                        props.onAddItem(responseData.data);
                    }else{
                        props.onAddItem(props.package);
                    }
                }else if(responseData.message && responseData.message == 'package already exist'){
                    showToastMessage("Package name already exist",'error');
                }else{
                    showToastMessage("Package not created","error");
                    // hide modal
                    props.onHide();
                }
                
                // enable submit button again
                setIsSubmitting(false);    
            } catch (error) {
                console.error(error);
                showToastMessage("Something went wrong while creating package",'error');
                // enable submit button again
                setIsSubmitting(false);    
                 // hide modal
                 props.onHide();
            }
        }
        
    }

    useEffect(() => {
        if(!Array.isArray(props.package)){
            setFormData((prevData) => ({ ...prevData, ['packageName']: props.package.name }));
            setFormData((prevData) => ({ ...prevData, ['packageDesc']: props.package.description }));
        }else{
            if (formRef.current.classList.contains('was-validated')) {
                formRef.current.classList.remove('was-validated')
            }
            // reset form data values
            resetForm();
        }
    },[props.package, props.modalShow])

    const closeModal = () => {
        resetForm();
        props.onHide();
    }

    return (
        <form onSubmit={handleSubmit} noValidate ref={formRef} id="addEditForm">
            <div className="modal-body">
                <div className="form-group">
                    <label>Package Name</label>
                    <input
                        type='text'
                        className="form-control"
                        id="packageName"
                        name="packageName"
                        onChange={handleInputChange}
                        value={formData.packageName ? formData.packageName : ''}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Package Description</label>
                    <textarea
                        className="form-control"
                        id="packageDesc"
                        name="packageDesc"
                        onChange={handleInputChange}
                        rows={4}
                        cols={50}
                        value={formData.packageDesc ? formData.packageDesc : ''}
                        required
                    />
                </div>
            </div>
            <div className='modal-footer'>
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    Proceed
                </button>
                <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={closeModal}
                >
                Close
                </button>
            </div>
        </form>
    )
}

export default AddEditPackages