const fileUrl = process.env.REACT_APP_FILE_URL;
export {fileUrl};
const backendUrl = process.env.REACT_APP_BACKEND_URL;
export {backendUrl};

// function to convert currency in to euro
export function EuroCurrency(value) {
    let newValue =  new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
    }).formatToParts(Number(value)).map(
        p => p.type != 'literal' && p.type != 'currency' ? p.value : ''
    ).join('')
    return newValue;
}

// function to make the api requests.
export async function CallApi (method,showToastMessage = null, params= null, data = null){
    // Set the default API URL
    const apiUrl = process.env.REACT_APP_API_URL;
    // get the token from the storage
    const apiToken  = localStorage.getItem('token');
    const url = `${apiUrl}${params ? params : ''}`;
    const options = {
        method,
        body: data ? data : null,
        headers: {
            'Authorization': `Bearer ${apiToken}`,
        }
    };
    
    const response = await fetch(url, options);
    if (!response.ok) {
        const errorData = await response.json(); // Assuming the error message is in JSON format
        if(typeof showToastMessage === 'function'){
          showToastMessage(errorData?.message,'error')
        }
        return errorData;
    }
    const responseData = await response.json();

    if(!responseData.status && responseData.message == "Unauthorized"){
        if(typeof showToastMessage === 'function'){
            showToastMessage(`Unauthorized request.`,'error')
        }
    }
    
    return responseData;
}

// function to convert date in required formate
export function getDateinFormate (dateString) {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear().toString().slice(-2);;
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(dateObj);
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
}

// generating secret key using AES-GCM 256
async function getKey (){
    const secretKey = await window.crypto.subtle.generateKey(
        {
            name: 'AES-GCM',
            length: 256,
        },
        true,
        ['encrypt', 'decrypt']
    );

    return secretKey;
}

// function to encrypt sensitive data
export async function encryptData (value){

    // generate Random bytes for to encryptData
    const generateRandomBytes = () => {
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
        return array;
    };

    // generate initial vector using random bytes
    const iv = generateRandomBytes();
    
    const secretKey = await getKey();

    const encoder = new TextEncoder();
    const data = encoder.encode(value);

    const encryptedData = await window.crypto.subtle.encrypt(
        {
            name: 'AES-GCM',
            iv,
        },
        secretKey,
        data
    );

    const encryptedDataBase64 = btoa(String.fromCharCode.apply(null, new Uint8Array(encryptedData)));
    const ivBase64 = btoa(String.fromCharCode.apply(null, iv));

    return encodeURIComponent(encryptedDataBase64)+'|'+encodeURIComponent(ivBase64);
}

export async function decryptData (data){

    const [encryptedData, iv] = data.split('|').map(decodeURIComponent);

    const encryptedDataArray = Uint8Array.from(atob(encryptedData), c => c.charCodeAt(0));
    const ivArray = Uint8Array.from(atob(iv), c => c.charCodeAt(0));

    const secretKey = await getKey();

    const decryptedData = await window.crypto.subtle.decrypt(
        {
          name: 'AES-GCM',
          iv: ivArray,
        },
        secretKey,
        encryptedDataArray
    );

    const decoder = new TextDecoder();
    const decodedData = decoder.decode(decryptedData);
    return decodedData;
}