export const login = (userData, token) => {
  // store the user data & token to the localstorage
  localStorage.setItem('user', JSON.stringify({userData}));
  localStorage.setItem('token', token);
  return {
    type: 'LOGIN',
    payload: JSON.stringify({userData}),
    token: token
}};
  
export const logout = () => {
  // remove the user data & token from the localstorage
  localStorage.removeItem('user')
  localStorage.removeItem('token')
  return {
    type: 'LOGOUT',
  }
};