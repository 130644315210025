/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from "react";
import UserDropDown from "./UserDropDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FullscreenContext } from "../../redux/fullScreenContext";

const Header = () => {
  let initialized = false
  const { isFullscreen } = useContext(FullscreenContext);
  useEffect(() => {
    
    const loadScript = async () => {
      const isScriptLoaded = Array.from(document.querySelectorAll('script')).some(script => script.getAttribute('src') === 'scripts/script.js');
      if (!isScriptLoaded){
        const script = document.createElement('script');
        script.src = 'scripts/script.js';
    
        // Add event listener for script load event
        script.addEventListener('load', () => {
        });
    
        // Add error event listener in case the script fails to load
        script.addEventListener('error', (error) => {
          console.error('Error loading sidebar script:', error);
        });
    
        // Append the script element to the document's body
        document.body.appendChild(script);
      }
    };
    if (!initialized) { 
      initialized = true
      loadScript();
    }
  }, []);

  return (
    <div style={{display: isFullscreen ? 'none' : 'block' }}>
      <div className="navbar-bg" />
      <nav className="navbar navbar-expand-lg main-navbar">
        <form className="form-inline me-auto">
          <ul className="navbar-nav mr-3">
            <li>
              <div
                data-bd-toggle="sidebar"
                className="nav-link nav-link-lg sidebar-toggle"
              >
                <FontAwesomeIcon icon={faBars} fontSize={18} fontWeight={900}/>
              </div>
            </li>

            <li>
              <a
                href="#"
                data-toggle="search"
                className="nav-link nav-link-lg d-sm-none"
              >
                <FontAwesomeIcon icon={faSearch} fontSize={18} style={{lineHeight: '32px'}}/>
              </a>
            </li>
          </ul>
        </form>
        <ul className="navbar-nav navbar-right ml-auto">
          <UserDropDown/>
        </ul>
      </nav>
    </div>
  );
};

export default Header;