import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { CallApi, EuroCurrency, fileUrl } from '../utils';
import { FullscreenContext } from '../redux/fullScreenContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faExpand } from '@fortawesome/free-solid-svg-icons';
import { useToast } from '../redux/toastContext';

const Offers = () => {
    const [description, setDescription] = useState('We would be very happy if our summary meets your approval. Do you have questions or need  further information? Call us - we are here for you.')
    const [leadInfo, setLeadInfo] = useState(null);
    const { state } = useLocation();
    const navigate = useNavigate();
    const selectedCards = state.selectedCards;
    const notes = state.notes;
    const leadId = state.leadId;
    const totalPrice = state.totalPrice;
    const discountedPrice = state.discountedPrice;
    const discountValue = state.discountValue;
    const discountType = state.discountType;
    const orderData = state.orderData;
    const company = state.company;
    const { isFullscreen, setIsFullscreen } = useContext(FullscreenContext);
    const { showToastMessage } = useToast();

    // function to fullscreen 
    const toggleFullscreen = () => {
        setIsFullscreen(true);
        var body = document.querySelector('body');
        body.classList.add('fullscreen-mode');
    }
    const back = () => {
        navigate(-1);
    }

    const saveOrder = async () => { 

        let params = `sales?id=${state.orderData.id}`;
        const formData = new FormData();
        formData.append('status', 1);
        formData.append('order_description', description);
        formData.append('company', company.id); 
        
        try {
            const responseData = await CallApi('POST','', params, formData);
            if(responseData.status){
                showToastMessage('Order Created','success');  
                navigate('/leads');
            }else{
                showToastMessage(responseData.message,'error')
            }
        } catch (error) {
            console.log(error)
            showToastMessage('Something went wrong. Action not performed.','error');
        }
    }

    const formateDate = (dateStr) => { 
        // Convert the date string to a Date object
        const date = dateStr ? new Date(dateStr) : new Date();

        // Array of month names
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        // Format the date as DD.MM.YYYY
        // Get the day, month, and year components
        const day = date.getDate().toString().padStart(2, '0');
        const monthIndex = date.getMonth();
        // Get the month name in character format
         const month = monthNames[monthIndex];
        const year = date.getFullYear();

        // Format the date as DD.MM.YYYY
        const formattedDate = `${day}.${month}.${year}`;
        
        return formattedDate;
    }

    const getLeadData = async () => { 
        const params = `leads/?id=${leadId}`;
        
        try {
            const responseData = await CallApi('GET',showToastMessage,params);
            if(responseData.data){
                setLeadInfo(responseData.data)
            }
        } catch (error) {
            
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');
        }
    }

    useEffect(() => {
        if(leadId){
            getLeadData();
        }
        setDescription(orderData.order_description ? orderData.order_description : company.summary_closing_default_note)
    }, [])

    const products = [
        { id: 1, name: 'Product 1', desc: 'lorem ipsum text testing description long.', qty: "1 piece", price: 10 },
        { id: 2, name: 'Product 2',desc: 'lorem ipsum text testing description long text testing desc.', qty: "Flat rate", price: 20 },
        { id: 3, name: 'Product 1',desc: 'lorem ipsum text testing description long text testing desc.', qty: "1 piece", price: 10 },
        { id: 4, name: 'Product 2',desc: 'lorem ipsum text testing description long text testing desc.', qty: "Flat rate", price: 20 },
        { id: 5, name: 'Product 1',desc: 'lorem ipsum text testing description long text testing desc.', qty: "1 piece", price: 10 },
        { id: 6, name: 'Product 2',desc: 'lorem ipsum text testing description long text testing desc.', qty: "Flat rate", price: 20 },
        { id: 7, name: 'Product 1',desc: 'lorem ipsum text testing description long text testing desc.', qty: "1 piece", price: 10 },
        { id: 8, name: 'Product 2',desc: 'lorem ipsum text testing description long text testing desc.', qty: "Flat rate", price: 20 },
        { id: 9, name: 'Product 1',desc: 'lorem ipsum text testing description long text testing desc.', qty: "1 piece", price: 10 },
        { id: 10, name: 'Product 2',desc: 'lorem ipsum text testing description long text testing desc.', qty: "Flat rate", price: 20 },
        { id: 11, name: 'Product 1',desc: 'lorem ipsum text testing description long text testing desc.', qty: "1 piece", price: 10 },
        { id: 13, name: 'Product 2',desc: 'lorem ipsum text testing description long text testing desc.', qty: "Flat rate", price: 20 },
        { id: 14, name: 'Product 1',desc: 'lorem ipsum text testing description long text testing desc.', qty: "1 piece", price: 10 },
        { id: 15, name: 'Product 2',desc: 'lorem ipsum text testing description long text testing desc.', qty: "Flat rate", price: 20 },
        { id: 16, name: 'Product 1',desc: 'lorem ipsum text testing description long text testing desc.', qty: "1 piece", price: 10 },
        { id: 17, name: 'Product 2',desc: 'lorem ipsum text testing description long text testing desc.', qty: "Flat rate", price: 20 },
        { id: 18, name: 'Product 1',desc: 'lorem ipsum text testing description long text testing desc.', qty: "1 piece", price: 10 },
        { id: 19, name: 'Product 2',desc: 'lorem ipsum text testing description long text testing desc.', qty: "Flat rate", price: 20 },
        { id: 20, name: 'Product 1',desc: 'lorem ipsum text testing description long text testing desc.', qty: "1 piece", price: 10 }
    ];

    // Calculate total
    const subtotal = products.reduce((sum, product) => sum + product.price, 0);
    const tax = subtotal * 0.1; // Assuming 10% tax
    

    return (
        <div className="main-content final-offer">
            <section className="section">
                {!isFullscreen && <div className="section-header justify-content-between">
                    <h1>Zusammenfassung</h1>
                    <div className='btn-grp'>
                        <button className='btn bt-icon' title="Back" onClick={() => back()} >
                            <FontAwesomeIcon icon={faArrowLeft} fontSize={20} className='align-middle'/>
                        </button>
                        <button className='btn bt-icon fullscreenmode-btn' title="fullscreen" onClick={toggleFullscreen}>
                            <FontAwesomeIcon icon={faExpand} fontSize={20} className='align-middle'/>
                        </button>
                    </div>
                </div>}
                <div className="my-3 mx-3">
                    <div className="row">
                        <div className='page-container'>
                            <div className='logo-container d-flex justify-content-end pb-5'>
                                <div className='logo'>
                                    <img src={company.company_logo ? fileUrl+`${company.company_logo}` : fileUrl+'logo.png'} />
                                </div>
                            </div>
                            <div className='company-info pt-5 pb-1'>
                                <p className='company-info-text'>{company?.company_name ?? ''} {company.street_no ? `| ${company.street_no}`: ''} {company.postal_code_city ? `| ${company.postal_code_city}` : ''}</p>
                            </div>
                            <div className='customer-info-container'>
                                <p className='customer-name mb-0'>{leadInfo?.company_name ?? ''}</p>
                                <p className='customer-address'>{leadInfo?.firstname ?? ''} {leadInfo?.lastname ?? ''}<br></br>{leadInfo?.street_no ?? ''}<br/> {leadInfo?.postal_code_city ?? ''}</p>
                            </div>
                            <div className='offer-dates-container d-flex justify-content-end align-contents-center pb-5'>
                                <div className='inner-container d-flex justify-content-end align-contents-center'>
                                    <div className='offer-date'>
                                        <p className='lable mb-0'>Zusammenfassung Datum</p>
                                        <p className='offer-date-value mb-0'>{formateDate(orderData?.order_date ?? '')}</p>
                                    </div>
                                    {/* Temporarily commente as we are not using it now*/}
                                    {/* <div className='offer-date'>
                                        <p className='lable mb-0'>Gültig bis</p>
                                        <p className='offer-date-value mb-0'>DD.MM.YYYY</p>
                                    </div> */}
                                </div>
                            </div>
                            <div className='offer-no-container pb-5'>
                                <p className='mb-0'>Zusammenfassung Nr. {orderData?.order_number ?? ''}</p>
                            </div>
                            <div className="normalText pb-5">
                                <p className='mb-0'>Wir bedanken uns für Ihre Anfrage. Gerne unterbreiten wir Ihnen hiermit folgendes Zusammenfassung:</p>
                            </div>
                            <div className='table'>
                                <div className='table-row d-flex mb-2'>
                                    <p className="table-col-header" style={{width: "7%"}}>Pos.</p>
                                    <p className="table-col-header" style={{width: "45%"}}>Bezeichnung</p>
                                    <p className="table-col-header" style={{width: "12%"}}>Menge</p>
                                    <p className="table-col-header" style={{width: "21%", textAlign: "right"}}>Einzel (€)</p>
                                    <p className="table-col-header" style={{width: "15%", textAlign: "right"}}>Gesamt (€)</p>
                                </div>
                                {selectedCards.map((product, index) => {
                                    return(
                                    <div key={index}>
                                        <div key={index} className="table-row d-flex align-items-center" wrap="none">
                                        <div className="table-col" style={{width: '7%'}}>{index + 1}</div>
                                        <div className="table-col" style={{width: '45%'}}>
                                            <div className="product-name">{product.card_title}</div>
                                            <div>{product.card_desc}</div>
                                        </div>
                                            <div className="table-col" style={{width: '12%'}}>Pauschal {/* or 1 Stück */}</div>
                                            <div className="table-col" style={{width: '21%',textAlign: 'right'}}>{EuroCurrency(product.card_price)} &euro;</div>
                                            <div className="table-col" style={{width: '15%',textAlign: 'right'}}>{EuroCurrency(product.card_price)} &euro;</div>
                                        </div>
                                    </div>
                                    )
                                })}
                            </div>
                            <div>
                                <div className='table-row d-flex' style={{paddingTop: 10}}>
                                    <div className='table-col' style={{width: '7%'}}></div>
                                    <div className='table-col' style={{width: '45%'}}></div>
                                    <div className='table-col' style={{width: '12%'}}></div>
                                    <div className='table-col' style={{width: '21%',textAlign: 'right'}}>Summe netto</div>
                                    <div className='table-col' style={{width: '15%',textAlign: 'right'}}>{EuroCurrency(totalPrice)} &euro;</div>
                                </div>
                                {discountValue ?
                                <div className='table-row d-flex' style={{paddingBottom: 10}}>
                                    <div className='table-col' style={{width: '7%'}}></div>
                                    <div className='table-col' style={{width: '45%'}}></div>
                                    <div className='table-col' style={{width: '12%'}}></div>
                                    <div className='table-col' style={{width: '21%',textAlign: 'right'}}>{discountType == '0' ? `Rabatt ${EuroCurrency(discountValue)}%`:'Rabattbetrag'}</div>
                                    <div className='table-col' style={{width: '15%',textAlign: 'right'}}>{EuroCurrency(discountedPrice)} &euro;</div>
                                </div> : ''}
                                <div className='table-row d-flex'>
                                    <div className='table-col' style={{width: '7%'}}></div>
                                    <div className='table-col' style={{width: '45%'}}></div>
                                    <div className='table-col' style={{width: '12%'}}></div>
                                    <div className='table-col' style={{width: '21%',textAlign: 'right',borderTop: '2px solid', paddingBottom: 10,  paddingTop: 5}}>
                                        Umsatzsteuer {EuroCurrency(19)}%
                                    </div>
                                    <div className='table-col' style={{width: '15%',textAlign: 'right',borderTop: '2px solid', paddingBottom: 10,  paddingTop: 5}}>
                                        {discountValue ? EuroCurrency((totalPrice - discountedPrice) * 0.19) : EuroCurrency(totalPrice * 0.19)} &euro;
                                    </div>
                                </div>
                                <div className='table-row d-flex'>
                                    <div className='table-col' style={{width: '7%'}}></div>
                                    <div className='table-col' style={{width: '45%'}}></div>
                                    <div className='table-col' style={{width: '12%'}}></div>
                                    <div className='table-col' style={{width: '21%',textAlign: 'right',borderTop: '1px solid', borderBottom: '1px solid', paddingTop: 5, borderBottomColor: '#277795', fontSize:21, fontWeight: 'bold', paddingBottom: 5}}>
                                        Rechnungsbetrag
                                    </div>
                                    <div className='table-col' style={{width: '15%',textAlign: 'right',borderTop: '1px solid', borderBottom: '1px solid', paddingTop: 5, borderBottomColor: '#277795', fontSize:21, fontWeight: 'bold', paddingBottom: 5}}>
                                        {discountValue ? EuroCurrency((totalPrice - discountedPrice) * 1.19) : EuroCurrency(totalPrice * 1.19)} &euro;
                                    </div>
                                </div>
                            </div>
                            <div className='normalText pt-5'>
                                <p><b>Anmerkungen :</b> {notes}</p>
                            </div>
                            <div>
                                <div className='normalText pb-5 pt-4'>
                                    <textarea
                                        className="form-control"
                                        id="description"
                                        rows="3"
                                        value={description}
                                        onChange={(event) => setDescription(event.target.value)}
                                    ></textarea>
                                </div>
                                <div className="regards">
                                    <p className='mb-0'>Mit freundlichen Grüßen</p>
                                    {company?.company_signature ? <img src={fileUrl+company?.company_signature} style={{height: 100, width: 'auto'}}/> : <p style={{fontSize: 28,color: '#277795'}}>{company?.company_name ?? ''}</p>}
                                </div>
                            </div>
                            
                            <div className='d-flex pdffooter'>
                                <div className='footer-container'>
                                    <div className='footer-col'>
                                        <p>{company?.company_name ?? ''}</p>
                                        {company.street_no ? <p>{company.street_no}</p> : ""}
                                        {company.postal_code_city ? <p>{company.postal_code_city}</p> : ''}
                                        {company.vat_id ? <p>Ust-IdNr:${company.vat_id}</p>: ""}
                                    </div>
                                    <div className='footer-col'>
                                        {company.phone ? <p>Phone: {company.phone}</p> : ''}
                                        {company.fax ? <p>Fax: {company.fax}</p> : ''}
                                        {company.email ? <p>E-Mail: {company.email}</p> : ''}
                                        {company?.website ? <p>Web: {company?.website}</p> : ''}
                                    </div>
                                    <div className='footer-col'>
                                        <p><b>Bank Details</b></p>
                                        {company.bank_name ? <p>{company.bank_name}</p>: '' }
                                        {company.IBAN ? <p>IBAN: {company.IBAN}</p>: ''}
                                        {company.BIC ? <p>BIC: {company.BIC}</p>: ''}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 d-flex justify-content-end">
                                <button type="button" className="btn btn-primary" onClick={saveOrder}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Speichern &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                            </div>
                        </div>
                        {/* <div className="col-sm-4">
                            <div className='clm-title'>
                                <span>Angebot</span>
                            </div>
                            <div className="d-flex">
                                <div>Nettopreis:</div>
                                <div className='ms-2'>{EuroCurrency(totalPrice)} &euro;</div>
                            </div>
                            <div className="d-flex">
                                <div>{discountType == '0' ? `Rabatt ${discountValue}%`:'Rabattbetrag'}:</div>
                                <div className='ms-2'>{EuroCurrency(discountedPrice)} &euro;</div>
                            </div>
                            <div className="d-flex">
                                <div>MwSt. 19%:</div>
                                <div className='ms-2'>{discountValue ? EuroCurrency((totalPrice - discountedPrice) * 0.19) : EuroCurrency(totalPrice * 0.19)} &euro;</div>
                            </div>
                            <div className="d-flex">
                                <div>Gesamtpreis:</div>
                                <div className='ms-2'>{discountValue ? EuroCurrency((totalPrice - discountedPrice) * 1.19) : EuroCurrency(totalPrice * 1.19)} &euro;</div>
                            </div>
                            <div className="mt-4 d-flex justify-content-between">
                                <button type="button" className="btn btn-primary" onClick={saveOrder}>Bestellung speichern</button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Offers