import React, { useContext } from 'react'
import { FullscreenContext } from '../redux/fullScreenContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompress } from '@fortawesome/free-solid-svg-icons';

const ExitFullScreen = () => {

    const {isFullscreen, toggleFullscreen } = useContext(FullscreenContext);

    const exitFullscreen = () => {
        toggleFullscreen(false);
    };

    return (
        <div className='floating-btn'>
        {isFullscreen && (
            <button className="btn exit-fullscreen-button" onClick={exitFullscreen} title="Back to Normal">
                <FontAwesomeIcon icon={faCompress} fontSize={20} className='align-middle py-1'/>
            </button>
        )}
        </div>
    )
}

export default ExitFullScreen