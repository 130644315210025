import React, { useRef, useState } from 'react'
import logo from "../assets/images/logo.svg";
import { CallApi } from '../utils';
import { useNavigate } from 'react-router-dom';
import { login } from '../redux/auth/Actions';
import { useDispatch } from 'react-redux';
import { useToast } from '../redux/toastContext';

const Login = () => {

    const { showToastMessage } = useToast();

    const formRef= useRef(null);
    const [formData, setFormData] = useState({
        email:'',
        password:''
    })

    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // set formdata data when input changed
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

     // Reset form data
     const resetForm = () => {
        setFormData({
            email: '',
            password:''
        });
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            form.classList.add("was-validated");
        }else{
             // Process form submission
             setIsSubmitting(true);
             
            let params =  `auth/login`;
            const fd = new FormData();
            for (let key in formData) {
                if (formData.hasOwnProperty(key)) {
                    fd.append(key, formData[key]);
                }
            }
            // submit form data to server using API with post method
            try {
                const responseData = await CallApi('POST',showToastMessage, params, fd);
                if(responseData.status){
                    document.getElementsByClassName('error')[0].innerText = '';   
                    // localStorage.setItem('token',responseData.data.token)
                    const userData = responseData.data.user;
                    dispatch(login(userData, responseData.data.token));
                    navigate("/");
                }else{
                    if(responseData.message){
                        document.getElementsByClassName('error')[0].innerText = responseData.message    
                    }else{
                        document.getElementsByClassName('error')[0].innerText = 'Authentication failed'
                    }
                }
                // enable submit button again
                setIsSubmitting(false);    
            } catch (error) {
                
                 // enable submit button again
                setIsSubmitting(false);    
            }
        }
    }
    return (
        <section className="section login">
            <div className="container pt-5">
                <div className="row">
                    <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                    <div className="login-brand">
                        <img
                        src={logo}
                        alt="logo"
                        width="100"
                        className="shadow-light rounded-circle"
                        />
                    </div>

                    <div className="card card-primary">
                        <div className="card-header">
                        <h4>Login</h4>
                        </div>
                        <div className="error">

                        </div>
                        <div className="card-body">
                            <form
                                method="POST"
                                noValidate
                                className="needs-validation"
                                ref={formRef}
                                onSubmit={handleSubmit} 
                                >
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        id="email"
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        tabIndex="1"
                                        required
                                        autoFocus
                                        onChange={handleInputChange}
                                    />
                                    <div className="invalid-feedback">
                                        Please fill in your email
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="d-block">
                                        <label htmlFor="password" className="control-label">
                                        Password
                                        </label>
                                        <div className="float-right">
                                            {/* we are not adding "forgot password" functionality. right now. we do it later version of application*/}
                                        {/* <a
                                            href="auth-forgot-password.html"
                                            className="text-small"
                                        >
                                            Forgot Password?
                                        </a> */}
                                        </div>
                                    </div>
                                    <input
                                        id="password"
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        tabIndex="2"
                                        required
                                        onChange={handleInputChange}
                                    />
                                    <div className="invalid-feedback">
                                        please fill in your password
                                    </div>
                                </div>
                                {/* we are not adding "Remember Me" functionality. right now. we do it later version of application*/}
                                {/* <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                    <input
                                    type="checkbox"
                                    name="remember"
                                    className="custom-control-input"
                                    tabIndex="3"
                                    id="remember-me"
                                    />
                                    <label
                                    className="custom-control-label"
                                    htmlFor="remember-me"
                                    >
                                    Remember Me
                                    </label>
                                </div>
                                </div> */}

                                <div className="form-group d-grid">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-lg btn-block"
                                        tabIndex="4"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? 'Loging...' : 'Login'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="simple-footer">
                        Copyright &copy; Summary Creator {new Date().getFullYear()}
                    </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login