import React from 'react'
import { EuroCurrency, fileUrl } from '../utils';
import { faLink, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Card = (props) => {
  return (
    <div className={`card mb-3 card-transition p-2`} key={props.card.card_id ? props.card.card_id : props.card.id} onClick={() => !props.lastColumn ? props.handleMoveProduct(props.card) : ''}>
        <div className="row no-gutters align-items-center">
            {props.card.card_image &&
            <div className={`${props.lastColumn ? 'col-md-3' : 'col-md-4'}`}>
                <img src={fileUrl+props.card.card_image} className="card-img" alt="Card image" />
            </div>}
            <div className={`${props.lastColumn ? (props.type == 'notes' ? 'col-md-12' : 'col-md-9') : 'col-md-8'}`}>
                <div className="card-body">
                    {props.card.card_title &&
                    <div className="card-title">
                        <span>{props.card.card_title}</span>
                    </div>}
                    {!props.lastColumn && <p className="card-text mb-2 text-small">{props.card.card_desc}</p>}
                    {props.card.card_price && <p className="card-text mb-0">
                        <small className="text-muted fw-bold">{EuroCurrency(props.card.card_price)} &euro;</small>
                    </p>}
                    {props.card.note && 
                    <div className='card-container'>
                        <b>Note:</b>
                        <p className='card-text card-notes'><span>{props.card.note}</span></p>
                    </div>}
                    {props.getProduct &&
                    <div className="card-icons bg-white">
                        {props.card.card_url && <span className="icon url">
                            <FontAwesomeIcon icon={faLink} onClick={(event) => {event.stopPropagation(); window.open(props.card.card_url,'_blank')}}/>
                        </span>}
                        <span className="icon edit">
                            <FontAwesomeIcon icon={faPencilAlt} onClick={(event) => {event.stopPropagation(); props.getProduct(props.card.card_id)}}/>
                        </span>
                        <span className="icon delete">
                            <FontAwesomeIcon icon={faTrashAlt} onClick={(event) => {event.stopPropagation(); props.deleteProduct(props.card.card_id)}}/>
                        </span>
                    </div>}
                    {props.card.note && 
                    <div className="card-icons bg-white">
                        <span className="icon edit">
                            <FontAwesomeIcon icon={faPencilAlt} onClick={(event) => {event.stopPropagation(); props.getNote(props.card.id)}}/>
                        </span>
                        <span className="icon delete">
                            <FontAwesomeIcon icon={faTrashAlt} onClick={(event) => {event.stopPropagation(); props.deleteNote(props.card.id)}} />
                        </span>
                    </div>}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Card