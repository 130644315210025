import React, { useEffect, useState } from 'react'
import { faListAlt, faMagnifyingGlass, faSquarePlus, faUserAltSlash, faUserEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '../../components/Pagination';
import ConfirmModal from '../../components/ConfirmModal';
import { CallApi, encryptData, getDateinFormate } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../redux/toastContext';
import Modal from '../../components/Modal';
import CompanyDropdown from '../../components/CompanyDropdown';

const LeadsList = (props) => {
    const [searchText, setSearchText] = useState('');
    const [leads, setLeads] = useState([]);
    const [confirmModalData, setConfirmModalData] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalTitle, setModalTitle] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [offerId, SetOfferId] = useState('');
    const [lastPage, setLastPage] = useState(1);
    const { showToastMessage } = useToast();

    const navigate = useNavigate();

    let initialized = false
    useEffect(() => {
        if (!initialized) { 
            initialized = true
            getLeads(searchText,1)
        }
    }, [searchText])

    // function to get leads data
    const getLeads = async (search,page) => {
        const params = search ? `leads/?search=${search}${page > 1 ? `&page=${page}`: ''}`: `leads${page > 1 ? `?page=${page}` : ''}`;
        try {
            const responseData = await CallApi('GET','',params);
            if(responseData.status){
                let usersData = responseData.data.data;
                setLeads(usersData);
                let pagination = responseData.data.pagination;
                setCurrentPage(pagination.currentPage);
                setLastPage(pagination.lastPage);
            }
        } catch (error) {
            
        }
    }

    // Function to handle deleting product
    const handleDeleteLeads =  (id) => {
        setShowConfirmModal(true);
        const confirmData = {
            "title":"Confirm Delete",
            "text": "Are you sure you want to delete this lead?",
            "id": id,
            "type":"user"
        }
        setConfirmModalData(confirmData);
    }

    // function to handle when the page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
        getLeads(searchText,page)
    }

    // function to handle whene modal confirm action performed
    const confirmModalAction = async (data) => {
        setShowConfirmModal(false);
        setConfirmModalData('');
        try {
            const params = `leads/?id=${data.id}`;
            const responseData = await CallApi('DELETE','',params);
            if(responseData.status){
                showToastMessage(`lead Deleted.`,'success');                                                                                                              
                getLeads(searchText,1);
            }else{
                showToastMessage("lead is not deleted",'error')
            }

        } catch (error) {
            
            showToastMessage("Something went wrong while deleted lead",'error');
                            
        }
    }
    
    const GotoOffer = async (id) => {
        setShowModal(true);
        setModalTitle("Select Company");
        SetOfferId(id);
        // alert("show campany list")
        // navigate(`/offers`,{state:{leadId:id}});
    }

    const GoToHistory = (id,company_name) => { 
        const appElement = document.getElementsByClassName('App')[0];
        sessionStorage.removeItem('myData');    
        sessionStorage.removeItem('company');    
        appElement.removeAttribute('data-route');   
        navigate(`/orders-history`,{state:{leadId:id,companyName:company_name}});
    }

    const modalHideAction = (company) => {
        setShowModal(false)
        if(company.id){
            setTimeout(() => {
                navigate(`/offers`,{state:{leadId:offerId,company:company}});
            }, 500);
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-form">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search" fdprocessedid="ijder" onChange={(e) => setSearchText(e.target.value)}/>
                                    <div className="input-group-btn">
                                        <button className="btn btn-primary" fdprocessedid="1higi">
                                            <FontAwesomeIcon icon={faMagnifyingGlass} style={{ fontWeight: 'bold', fontSize: "13px" }}/>
                                        </button>
                                    </div>
                                </div>
                        </div>
                        <div className='card-header-action'>
                            <button type="button" className='btn btn-icon icon-left btn-primary' onClick={() => props.addUser(null)}>
                                <FontAwesomeIcon icon={faUserPlus} fontSize={14} className='me-2'/>
                                <span>Add Lead</span>
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className='table-responsive'>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Company Name</th>
                                    <th scope="col">Customer Name</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Created On</th>
                                    <th scope="col">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {leads.length > 0 ?
                                    leads.map((data, index) => {
                                        // Calculate the adjusted index based on the current page
                                        const adjustedIndex = (currentPage - 1) * 10 + index + 1;

                                        const address = [];
                                        
                                        if (data.street_no) {
                                            address.push(data.street_no);
                                        }
                                        
                                        if (data.postal_code_city) {
                                            address.push(data.postal_code_city);
                                        }
                                        
                                        if (data.country) {
                                            address.push(data.country);
                                        }
                                        return(
                                        <tr key={index}>
                                            <th scope="row">{adjustedIndex}</th>
                                            <td>{data.company_name}</td>
                                            <td>{data.firstname+(data.lastname ? ' '+data.lastname : '')}</td>
                                            <td>{address.join(', ')}</td>
                                            <td>{data.email}</td>
                                            <td>{getDateinFormate(data.created_at)}</td>
                                            <td>
                                                <button type='button' className='btn btn-sm btn-icon icon-left btn-primary me-2' onClick={() => GotoOffer(data.id)} title='Create Summary'>
                                                    <FontAwesomeIcon icon={faSquarePlus} fontSize={14}/>
                                                </button>
                                                <button type='button' className='btn btn-sm btn-icon icon-left btn-primary me-2' onClick={() => GoToHistory(data.id, data.company_name)} title='Orders History'>
                                                    <FontAwesomeIcon icon={faListAlt} fontSize={14}/>
                                                </button>
                                                <button className='btn btn-sm btn-icon icon-left btn-primary me-2' onClick={() => props.addUser(data.id)} title='Edit Lead'>
                                                    <FontAwesomeIcon icon={faUserEdit} fontSize={14}/>
                                                </button>
                                                <button type='button' className='btn btn-sm btn-icon icon-left btn-danger ' onClick={() => handleDeleteLeads(data.id)} title='Delete Lead'>
                                                    <FontAwesomeIcon icon={faUserAltSlash} fontSize={14}/>
                                                </button>
                                            </td>
                                        </tr>)
                                    })
                                    :
                                    <tr>
                                        <td colSpan="10"><center>No Records Found</center></td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    
                    </div>
                    <div className='card-footer text-end'>
                        <nav className='d-inline-block'>
                            <Pagination currentPage={currentPage} lastPage={lastPage} onPageChange={handlePageChange} />
                        </nav>
                    </div>
                </div>
                </div>
            </div>
             {/* Custom modal In which we can set content from here */}
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                title={modalTitle}
            >
                <CompanyDropdown
                    modalShow={showModal} 
                    onHide={(data) => modalHideAction(data)}
                />
            </Modal>

            {/* Confirm modal to confirm action before it perform */}
            <ConfirmModal show={showConfirmModal} data={confirmModalData} onConfirm={(data) => confirmModalAction(data)} setShowConfirmModal={(val) => setShowConfirmModal(val)}/>
        </>
    )
}

export default LeadsList