import React, { useEffect, useRef, useState } from 'react'
import { CallApi } from '../../utils';
import { useToast } from '../../redux/toastContext';

const AddEditNotes = (props) => {
    const [formData, setFormData] = useState({
        note:''
    })
    const [isSubmitting, setIsSubmitting] = useState(false);

    const formRef = useRef(null);
    const { showToastMessage } = useToast();

    // set formdata data when input changed
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    // Reset form data
    const resetForm = () => {
        setFormData({
            note: '',
        });
    }

    // Handle Submitted form data
    const handleSubmit = async(e) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;

        // check form validity
        if (form.checkValidity() === false) {
            // add form validator class to validate form
            form.classList.add("was-validated");
        }else{
            // Process form submission
            setIsSubmitting(true);

            //check if notes props present then update note else create note
            let params = 'notes';

            const fd = new FormData();
            for (let key in formData) {
                if (formData.hasOwnProperty(key)) {
                    fd.append(key, formData[key]);
                }
            }

            if(props.module == 'package'){
                fd.append('package_id',props.packageId)
            }else if(props.module == 'orderhistory'){
                fd.append('id',props.orderid)
            }else{
                fd.append('lead_id',props.leadId)
            }
            // submit form data to server using API with post method
            try {
                const responseData = await CallApi('POST',props.showToastMessage, params, fd);
                if(responseData.status){
                    showToastMessage(`Order note updated `,'success')
                    // reset form data values
                    resetForm();
                    // pass data to onAddProduct() on home page
                    props.onAddItem({data:responseData,source:"note"});
                }else{
                    showToastMessage("Note not added",'error');
                }
                // reset form data values
                resetForm();
                // pass data to onAddProduct() on home page
                props.onAddItem({data:responseData,source:"note"});
                // enable submit button again
                setIsSubmitting(false);    
            } catch (error) {
                console.error(error);
                // reset form data values
                resetForm();
                // pass error to onAddProduct() on home page
                props.onAddItem(error)
                // enable submit button again
                setIsSubmitting(false);    
            }
            
            props.onHide();
        }
    };

    useEffect(() => {
        if(props.notes){
            setFormData((prevData) => ({ ...prevData, note: props.notes }));
        }else{
            if (formRef.current.classList.contains('was-validated')) {
                formRef.current.classList.remove('was-validated')
            }
            // reset form data values
            resetForm();
        }
    },[props.notes,props.modalShow])

    return (
        <form onSubmit={handleSubmit} noValidate ref={formRef}>
            <div className="modal-body">
                <div className="form-group">
                    <textarea
                    className="form-control"
                    id="note"
                    name="note"
                    onChange={handleInputChange}
                    rows={4}
                    cols={50}
                    value={formData.note ? formData.note : ''}
                    required
                    />
                </div>
            </div>
            <div className='modal-footer'>
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    {isSubmitting ? 'Saving...' : 'Save'}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={props.onHide}
                >
                  Close
                </button>
            </div>
        </form>
    )
}

export default AddEditNotes