import React, { useEffect, useRef, useState } from 'react'
import { CallApi } from '../utils'
import Select from 'react-select';
import { useToast } from '../redux/toastContext';


const PackagesDropdown = (props) => {
    const [packages, setPackages] = useState([])
    const [respData, setRespData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedPackage,setSelectedPackage] = useState('')
    const [selectedOption, setSelectedOption] = useState(null);
    const selectRef = useRef(null);
    const { showToastMessage } = useToast()

    const getPackages = async() => { 
        setIsLoading(true);
        const params = `packages?page=${currentPage}${search ? `&search=${search}` : ''}`;
        // Make an API call to fetch paginated data
        try {
            const responseData = await CallApi('GET','',params);
            if(responseData.status){
                setIsLoading(false);
                if(responseData.data.data.length > 0){
                     // Append the new options to the existing options
                    let data = responseData.data.data.map((pkg) => ({ value: pkg.id, label: pkg.name }));
                    setPackages((prevOptions) => [...prevOptions, ...data])
                    setRespData(responseData.data.data);
                    
                    setTotalPages(responseData.data.pagination.lastPage);
                }
            }else{
                setIsLoading(false);
                setPackages([{value: 0, label: "No Package Available"}])
            }
        } catch (error) {
            setIsLoading(false);
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');
        }
    }

    const selectedOffers = (e) => {
        if(e){
            setSelectedOption(e.value);
            let selectedPkg = respData.filter((pkg) => pkg.id === e.value);
            setSelectedPackage(selectedPkg[0]);
            let data = respData.map((pkg) => ({ value: pkg.id, label: pkg.name }));
            setPackages((prevOptions) => [...prevOptions, ...data])
        }
    }

    const applyPackage = () => {
        if(selectedPackage){
            props.selectedPackage(selectedPackage);
            selectRef.current.setValue('')
            props.onHide();

        }else{
            showToastMessage('Please select package to apply','error');
        }
    }

    const handleScroll = (e) => { 
        const { target } = e;

        if (
        target.scrollTop + target.offsetHeight === target.scrollHeight &&
        !isLoading && currentPage < totalPages
        ) {
            // Increment the page number for the next fetch
            setCurrentPage((prevPage) => prevPage + 1);
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown') {
            const selectMenu = selectRef.current.menuListRef;
            const isScrolledToBottom =
              selectMenu.scrollTop + selectMenu.offsetHeight ===
              selectMenu.scrollHeight;
      
            if (isScrolledToBottom && !isLoading && currentPage < totalPages) {
                // Increment the page number for the next fetch
                setCurrentPage((prevPage) => prevPage + 1);
            }
        }
    };

    const handleInputChange = (inputValue) => {
        setPackages([]);
        setCurrentPage(1);
        setSearch(inputValue);
    }

    let initialized = false
    useEffect(() => {
        getPackages();
    }, [currentPage, search, props.modalShow]);
    

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='packages-drpodown py-3 px-3'>
                        <Select 
                            className='basic-single'
                            classNamePrefix="select"
                            defaultValue=""
                            isSearchable={true}
                            name='Package'
                            options={packages}
                            placeholder="Select Package"
                            noOptionsMessage={() => "No packages available"}
                            onChange={selectedOffers}
                            isLoading={isLoading}
                            onMenuScrollToBottom={handleScroll}
                            onKeyDown={handleKeyDown}
                            onInputChange={handleInputChange}
                            isClearable={true}
                            ref={selectRef}
                        />
                    </div>
                </div>
            </div>
            <div className='modal-footer'>
                <button type="submit" className="btn btn-primary" onClick={applyPackage}>
                    Apply
                </button>
                <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={props.onHide}
                >
                    Close
                </button>
            </div>
        </div>
    )
}

export default PackagesDropdown