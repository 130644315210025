import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, faArrowLeft, faArrowRight, faArrowRotateBack, faExpand, faNoteSticky, faPlus, faTags } from '@fortawesome/free-solid-svg-icons'
import Card from '../components/Card'
import Modal from '../components/Modal'
import AddEditNotes from '../components/FormModals/AddEditNotes'
import ConfirmModal from '../components/ConfirmModal'
import AddEditProductModal from '../components/AddEditProductModal'
import Toast from '../components/Toast'
import { EuroCurrency, CallApi, toggleFullscreen } from '../utils'
import AddEditDiscount from '../components/FormModals/AddEditDiscount'
import { FullscreenContext } from '../redux/fullScreenContext'
import { useSelector } from 'react-redux'
import Offers from '../components/Offers'
import PackagesDropdown from '../components/PackagesDropdown'
import { useToast } from '../redux/toastContext'
import { debounce } from 'lodash';

const Home = () => {
    const [selectedCards, setSelectedCards] = useState([]);
    const [cards, setCards] = useState([])
    const [totalPrice, setTotalPrice] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [modalFor, setModalFor] = useState('');
    const [modalTitle, setModalTitle] = useState(false);
    const [singleProduct, setSingleProduct] = useState('');
    const [searchText, setSearchText] = useState('');
    const [notes, setNotes] = useState('');
    const [confirmModalData, setConfirmModalData] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [nextAvailableOrder, setNextAvaialableOrder] = useState(0);
    const [discountType, setDiscountType] = useState('');
    const [discountValue, setDiscountValue] = useState('');
    const [discountedPrice, setDiscountedPrice] = useState(0);
    const [discountData, setDiscountData] = useState('');
    const [orderData, setOrderData] = useState('');
    const [vat, setVat] = useState(0);
    const [total, setTotal] = useState(0);
    const [isClicked, setIsClicked] = useState(false);

    const { showToastMessage } = useToast()

    const navigate = useNavigate();

    const { state } = useLocation();

    const leadId =  state ? state.leadId : null;

    const company = state ? state.company: null;

    const { isFullscreen, toggleFullscreen } = useContext(FullscreenContext);

    const authUser = JSON.parse(useSelector(state => state.auth.user));

    // Function to handle product selection
    const handleProductSelect = debounce(async (clickedCard) => {
        if (!isClicked) {
            setIsClicked(true);
            
            let params = `sales/addCard`;
            const formData = new FormData();
            formData.append('lead_id', leadId);
            formData.append('card_id', clickedCard.card_id);
            formData.append('card_price', clickedCard.card_price);
            formData.append('company', company.id);
            if(discountValue){
                formData.append('discount_type', discountType);
                formData.append('discount_value', discountValue);
            }
            if(notes){
                formData.append('notes', notes);
            }
            formData.append('created_by', authUser.userData.id);
            try {
                const responseData = await CallApi('POST',showToastMessage, params, formData);
                if(responseData.status){
                    getProducts();  
                    getOrder();
                }else{
                    showToastMessage(responseData.message,'error')
                }
                setIsClicked(false);
            } catch (error) {
                setIsClicked(false);
                console.log(error)
                showToastMessage('Something went wrong. Action not performed.','error');
            }
        }
    }, 500);

    // Function to handle deselecting products
    const handleDeSelectedProducts = async (clickedCard) => {
        let params = `sales/removeCard`;
        const formData = new FormData();
        formData.append('lead_id', leadId);
        formData.append('card_id', clickedCard.card_id);
        formData.append('card_price', clickedCard.card_price);
        try {
            const responseData = await CallApi('POST',showToastMessage, params, formData);
            if(responseData.status){
                getProducts();  
                getOrder();
            }else{
                showToastMessage(responseData.message,'error')
            }
        } catch (error) {
            console.log(error)
            showToastMessage('Something went wrong. Action not performed.','error');
        }
    }

    const getOrder = async () => {
        const params = `sales/?leadId=${leadId}`;
        
        try {
            const responseData = await CallApi('GET',showToastMessage,params);
            if(responseData.data){
                if(responseData.data.selectedCards.length > 0){
                    setSelectedCards(responseData.data.selectedCards);
                }else{
                    setSelectedCards([]);
                }
                if(responseData.data.orderData){
                    const orderData = responseData.data.orderData;

                    setOrderData(orderData);
                    setTotalPrice(orderData.subtotal);
                    setVat(orderData.vat)
                    setTotal(orderData.total)
                    if(orderData.note){
                        setNotes(orderData.note)
                    }else{
                        setNotes('')
                    }

                    let discount = orderData.discount_value
                    let discountType = orderData.discount_type
                    let discountedPrice = discountType == "0" ? orderData.subtotal * discount/100 : discount;
                    setDiscountedPrice(discountedPrice);
                    setDiscountType(discountType)
                    setDiscountValue(discount)
                    setDiscountData(orderData)
                }else{
                    setTotalPrice(0);
                    setVat(0)
                    setTotal(0)
                }
            }
        } catch (error) {
            
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');
        }
    }

    // Function to hide modal
    const handleShowModal = () => {
        getProducts();
        setSingleProduct('');
        setShowModal(true);
    };


    // Function to handle add new product
    const handleAddProduct = (data) => {
        if(data.status && (data.message == 'Insert Record' || data.message == 'Update Record')){
            getProducts();
            let msgVar = data.message == 'Insert Record' ? 'added' : 'updated';
            showToastMessage(`Product ${msgVar} successfully`,'success');
        }else if(data.message == 'Not added' || data.message == 'Not updated'){
            let msgVar = data.message == 'Insert Record' ? 'added' : 'updated';
            showToastMessage(`Product not ${msgVar}`,'error');
        }else if(data.message == 'Resource not found'){
            showToastMessage(`Requested resource not found on this server`,'error');
        }else{
            showToastMessage(`Somethign went wrong. Please contact to developer.`,'error');
        }
    };

    // Function to get products list
    const getProducts = async (search) => {
        const params = search ? `products/?leadId=${leadId}&search=${search}`: `products/?leadId=${leadId}`;
        
        try {
            const responseData = await CallApi('GET',showToastMessage,params);
            if(responseData.data && responseData.data.length > 0 ){

                const maxOrder = Math.max(...responseData.data.map((item) => item.card_order), 0);
                setNextAvaialableOrder(maxOrder + 1);
                setCards(responseData.data.filter(item => item.in_sales === 0));
                
            }else{
                setCards([]);
            }
        } catch (error) {
            
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');
        }
    }

    // Function to get single product details
    const getProduct = async (id) => {
        try {
            const params = `products/?id=${id}`;
            const responseData = await CallApi('GET',showToastMessage,params);
            setSingleProduct(responseData.data);
            setShowModal(true);                                                                                                                     
        } catch (error) {
            
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');
        }
    }

    // Function to redirect to next page when submit offer button clicked
    const submitOffer = () => {
        navigate('/angebot-anze',{state:{totalPrice:totalPrice,discountType:discountType,discountValue:discountValue,discountedPrice:discountedPrice, leadId:leadId, orderData: orderData,notes:notes,selectedCards:selectedCards,company: company}})
    }

    // Function to delete single product
    const deleteProduct = async (id) => {
        try {
            const params = `products/?id=${id}`;
            const responseData = await CallApi('DELETE',showToastMessage,params);
            if(responseData.status){
                getProducts();
                showToastMessage(`Card deleted.`,'success');                      
            }else{
                showToastMessage("Card not deleted","error")
            }
                                                                                                    
        } catch (error) {
            
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');
        }
    }

    // Function to delete single note
    const deleteNote = async () => {
        try {
            const params = `notes/?id=${orderData.id}&mod=sales`;
            const responseData = await CallApi('DELETE',showToastMessage,params);
            if(responseData.status){
                getOrder();
                showToastMessage(`Note Deleted.`,'success');                                                                                                              
            }else{
                showToastMessage("Note not deleted",'error');
            }
        } catch (error) {
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');                      
        }
    }

    // Function to handle when add new note added & get refreshed list
    const onAddItem = (data, source) => {
        if(data.data.status){
            getOrder();
        }
    }

    // function to get notes list
    const getNotes = async (openModal) => {
        setModalFor("notes")
        if(notes){
            if(openModal){
                setShowModal2(true);
                setModalTitle("Edit Notes");
            }
        }else{
            setNotes('')
            if(openModal){
                setShowModal2(true);
                setModalTitle("Add Notes");
            }  
        }
    }

    // function to add/edit discount
    const addDiscount = async (openModal,totalPrice) => {
        setModalFor("discount")
        if(openModal && discountData){
            setShowModal2(true);
            setModalTitle("Edit Discount");
        }else if(openModal){
            setShowModal2(true);
            setModalTitle("Add Discount");
        }
    }

    // show package list
    const showPackages = () => { 
        setModalFor("packages")
        setShowModal2(true);
        setModalTitle("Select Package");   
    }

    // Function to handle deleting product
    const handleDeleteProduct =  (id) => {
        setShowConfirmModal(true);
        const confirmData = {
            "title":"Confirm Delete",
            "text": "Are you sure you want to delete this card?",
            "id": id,
            "type":"product"
        }
        setConfirmModalData(confirmData);
    }

    // Function to handle deleting note
    const handleDeleteNote =  () => {
        setShowConfirmModal(true);
        const confirmData = {
            "title":"Confirm Delete",
            "text": "Are you sure you want to delete this Note?",
            "type":"note"
        }
        setConfirmModalData(confirmData);
    }

    // Function to confirm any action being performed
    const confirmModalAction = (data) => {
        if(data.type == "product"){
            deleteProduct(data.id)
        }
        if(data.type == 'note'){
            deleteNote()
        }
        setShowConfirmModal(false);
        setConfirmModalData('');
    }

    const backToLeads = () => { 
        navigate(-1);
    }

    const selectedPackage = async (value) => {
        let params = `sales${orderData ? `?id=${orderData.id}` : ''}`;
        const formData = new FormData();

        formData.append('lead_id', leadId);
        formData.append('selected_cards', value.selected_cards);
        formData.append('subtotal', value.subtotal);
        formData.append('discount_type', value.discount_type);
        if(value.discount_value){
            formData.append('discount_value', value.discount_value);
        }
        
        formData.append('vat', value.vat);
        formData.append('total', value.total);
        if(value.note){
            formData.append('note', value.note );
        }
        if(!orderData){
            formData.append('created_by', authUser.userData.id);
        }
        try {
            const responseData = await CallApi('POST',showToastMessage, params, formData);
            if(responseData.status){
                getProducts();  
                getOrder();
            }else{
                showToastMessage(responseData.message)
            }
        } catch (error) {
            console.log(error)
            showToastMessage('Something went wrong. Action not performed.','error');
        }
    }

    let initialized = false
    useEffect(() => {
        if (!initialized) { 
            if(!leadId){
                navigate('/leads', { replace: true });
            }
            initialized = true
            getProducts(searchText)
            getOrder();
        }
    }, [searchText]);

  return (
    <>
    <div className="main-content">
        <section className="section">
            {!isFullscreen && <div className="section-header justify-content-between">
                <div className='d-flex align-items-center '>
                    <h1 className='me-2'>Summary</h1>
                    <h1>(Company : {company ? company.company_name : ''})</h1>
                </div>
                <div className='btn-grp'>
                    <button className='btn bt-icon' title="Back" onClick={() => backToLeads()} >
                        <FontAwesomeIcon icon={faArrowLeft} fontSize={20} className='align-middle'/>
                    </button>
                    <button className='btn bt-icon fullscreenmode-btn' title="Fullscreen" onClick={() => toggleFullscreen(true)}>
                        <FontAwesomeIcon icon={faExpand} fontSize={20} className='align-middle'/>
                    </button>
                </div>
            </div>}
            
            <Offers 
                setSearchText={setSearchText} 
                cards={cards}
                notes={notes}
                selectedCards={selectedCards} 
                handleProductSelect={handleProductSelect} 
                handleDeSelectedProducts={handleDeSelectedProducts}
                submitOffer={submitOffer}
                addDiscount={addDiscount}
                getNotes={getNotes}
                handleDeleteProduct={handleDeleteProduct}
                getProduct={getProduct}
                handleShowModal={handleShowModal}
                handleDeleteNote={handleDeleteNote}
                showPackages={showPackages}
                totalPrice={totalPrice}
                discountType={discountType}
                discountValue={discountValue}
                discountedPrice={discountedPrice}
                vat={vat}
                total={total}
                screen="leads"
            />
            {/* Add/Edit Product Modal */}
            <AddEditProductModal
                show={showModal}
                onHide={() => setShowModal(false)}
                onAddProduct={handleAddProduct}
                product={singleProduct}
                AddEditProductModal={AddEditProductModal}
                nextOrderId={nextAvailableOrder}
            />
            {/* Custom modal In which we can set content from here */}
            <Modal
                show={showModal2}
                onHide={() => setShowModal2(false)}
                title={modalTitle}
            >
                {(() => {
                    switch (modalFor) {
                    case 'notes':
                        return (
                        // Add / Edit Notes Form in modal
                        <AddEditNotes
                            modalShow={showModal2}
                            onHide={() => setShowModal2(false)}
                            notes={notes}
                            leadId={leadId}
                            onAddItem={(data) => onAddItem(data, 'note')}
                        />
                        );
                    case 'discount':
                        return (
                            // Add / Edit Discount Form in modal
                            <AddEditDiscount
                                modalShow={showModal2}
                                onHide={() => setShowModal2(false)}
                                selectedItems={selectedCards}
                                leadId={leadId}
                                totalPrice={totalPrice}
                                discount={discountData}
                                onAddItem={(data) => onAddItem(data, 'discount')}
                            />
                        );
                    case 'packages' :
                        return (
                            <PackagesDropdown modalShow={showModal2} selectedPackage={selectedPackage} onHide={() => setShowModal2(false)}/>
                        );
                    default:
                        return null;
                    }
                })()}
            </Modal>
            {/* Confirm modal to confirm action before it perform */}
            <ConfirmModal show={showConfirmModal} data={confirmModalData} onConfirm={(data) => confirmModalAction(data)} setShowConfirmModal={(val) => setShowConfirmModal(val)}/>
        </section>
    </div>
    </>
  )
}

export default Home