import React, { useEffect, useRef, useState } from 'react'
import Card from '../Card';
import { EuroCurrency, fileUrl, CallApi } from '../../utils';
import { useToast } from '../../redux/toastContext';

const AddEditDiscount = (props) => {
    const [formData, setFormData] = useState({
        disType:'0',
        disValue:''
    })
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [total, setTotal] = useState(props.totalPrice);
    const [discountType, setDiscountType] = useState(0);
    const [disocuntedPrice, setDiscountedPrice] = useState(0);
    const [disocuntedValue, setDiscountedValue] = useState('');
    const [error, setError] = useState('');
    const formRef = useRef(null);
    const { showToastMessage } = useToast();

     // Reset form data
     const resetForm = () => {
        setFormData({
            disType:'0',
            disValue:''
        });
        setTotal(props.totalPrice);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;

        // check form validity
        if (form.checkValidity() === false) {
            // add form validator class to validate form
            form.classList.add("was-validated");
        }else{
            if(error){
                return false;
            }

            let params = 'discount';

            const fd = new FormData();
            for (let key in formData) {
                if (formData.hasOwnProperty(key)) {
                    fd.append(key, formData[key]);
                }
            }
            if(props.module == 'package'){
                fd.append('package_id',props.packageId)
            }else if(props.module == 'orderhistory'){
                fd.append('id',props.orderid)
            }else{
                fd.append('lead_id',props.leadId)
            }
            
            // Process form submission
            setIsSubmitting(true);

            // submit form data to server using API with post method
            try {
                const responseData = await CallApi('POST',props.showToastMessage, params, fd);
                if(responseData.status){
                    showToastMessage(`Discount ${props.data ? 'updated': 'added'}`,'success')
                    // reset form data values
                    resetForm();
                    // pass data to onAddProduct() on home page
                    props.onAddItem({data:responseData,source:"discount"});
                }else{
                    showToastMessage("Discount not added",'error');
                }
                
                // enable submit button again
                setIsSubmitting(false);    
            } catch (error) {
                console.error(error);
                showToastMessage("Something went wrong while adding discount",'error');
                setIsSubmitting(false);    
            }

            props.onHide();
        }
    }

    const handleDiscountType = (e) => {
        setFormData((prevData) => ({ ...prevData, ['disType']: e.target.value }));
        if(e.target.value == 0){
            var discountPrice = props.totalPrice * formData.disValue/100
            setDiscountedPrice(discountPrice);
            var finalTotal = props.totalPrice - discountPrice
            setTotal(finalTotal)
        }else{
            setDiscountedPrice(formData.disValue);
            var finalTotal = props.totalPrice - formData.disValue
            setTotal(finalTotal)
        }
    }

    const handleDiscountValue = (value) => {
        // Remove any non-numeric characters from the input value
        const numericValue = value == 0 ? '' : value.replace(/[^0-9,.]/g, '');
        // Split the value by the decimal point
        const parts = numericValue.split(/['.,']/);
        // Ensure there is at most one decimal point
        if (parts.length > 2) {
            // Remove any additional decimal points
            parts.splice(2);
        }
        // Reconstruct the value with at most one decimal point
        let inputDisValue = '';
        if(numericValue.includes(',')){
            inputDisValue = parts.join(',');
        }else{
            inputDisValue = parts.join('.');
        }

        setDiscountedValue(inputDisValue)

        let cleanedValue = parts.join('.');
        
        // set the formData
        setFormData((prevData) => ({ ...prevData, disValue: cleanedValue }));
        
        if(formData.disType == 0){
            if (parseFloat(cleanedValue) > 100) {
                setError('Invalid value for discount.');
                setTotal(props.totalPrice);
            }else {
                setError('');
                var discountPrice = props.totalPrice * cleanedValue/100
                setDiscountedPrice(discountPrice);
                var finalTotal = props.totalPrice - discountPrice
                setTotal(finalTotal)
            }
        }else{
            if(parseFloat(cleanedValue) > parseFloat(props.totalPrice)){
                setError('Invalid value for discount.');
                setTotal(props.totalPrice);
            }else{
                setError('');
                setDiscountedPrice(cleanedValue);
                var finalTotal = props.totalPrice - cleanedValue
                setTotal(finalTotal)
            }
            
        }
    }

    useEffect(() => {
        if(props.discount){
            let discountValue = props.discount.discount_value ? props.discount.discount_value : 0
            let discountType = props.discount.discount_type ? props.discount.discount_type : 0
            setFormData((prevData) => ({ ...prevData, disType: discountType }));
            setFormData((prevData) => ({ ...prevData, disValue: discountValue }));
            if(discountValue > 0){
                const parts = discountValue?.split(/['.,']/);
                // Ensure there is at most one decimal point
                if (parts.length > 2) {
                    // Remove any additional decimal points
                    parts.splice(2);
                }
                // Reconstruct the value with at most one decimal point
                let inputDisValue = '';
                if(discountValue.includes('.')){
                    inputDisValue = parts.join(',');
                }
        
                setDiscountedValue(inputDisValue)
            }
            

            if(props.discount){
                let discountType = props.discount.discount_type
                if(discountType == 0){
                    var discountPrice = props.totalPrice * discountValue/100
                    setDiscountedPrice(discountPrice);
                    var finalTotal = props.totalPrice - discountPrice
                    setTotal(finalTotal)
                }else{
                    setDiscountedPrice(discountValue);
                    var finalTotal = props.totalPrice - discountValue
                    setTotal(finalTotal)
                }
            }
        }else{
            if (formRef.current.classList.contains('was-validated')) {
                formRef.current.classList.remove('was-validated')
            }
            // reset form data values
            resetForm();
        }
    },[props.discount,props.modalShow])

    return (
        <form onSubmit={handleSubmit} noValidate ref={formRef}>
            <div className="modal-body">
                <div className='listItems'>
                    {props.selectedItems.map((card, index) => (
                        <div className='item row border-bottom mb-2 align-items-center' key={index}>
                            <div className='col-md-2'>
                               <center className='mb-2'><img src={fileUrl+card.card_image} height={50} className="w-100 object-fit-contain" alt="Card image" /></center>
                            </div>
                            <div className='col-md-7'>
                                <p className='card-title'>{card.card_title}</p>
                            </div>
                            <div className='col-md-3'>
                                <p className="card-text text-end">
                                    <small className="text-muted fw-bold">{EuroCurrency(card.card_price)} &euro;</small>
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
                {props.selectedItems.length > 0 && (
                    <div className='listItems'>
                        <div className='item row align-items-center py-3'>
                            <div className='col-md-6'>
                                <div className='card-subtitle fs-6'>Nettopreis:</div>
                            </div>
                            <div className='col-md-6'>
                                <div className='text-end'>{EuroCurrency(props.totalPrice)} &euro;</div>
                            </div>
                        </div>
                        <div className='item row align-items-center'>
                            <div className='col-md-6'>
                                <div className='card-subtitle fs-6'>Rabatttyp:</div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group mb-0 mt-2">
                                    <select name="discount_type" className="form-control discount-type" id="disocunt_type" onChange={handleDiscountType} value={formData.disType}>
                                        <option value="0">Prozentsatz</option>
                                        <option value="1">Absolut</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='item row align-items-center'>
                            <div className='col-md-6'>
                                <div className='card-subtitle fs-6'>{formData.disType == 0 ? 'Rabatt %' : 'Rabattbetrag' }:</div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group mb-0 mt-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="discount_value"
                                        name="discount_value"
                                        value={disocuntedValue}
                                        onChange={(event) => handleDiscountValue(event.target.value)}
                                    />
                                     {error && <p className='error'>{error}</p>}
                                </div>
                            </div>
                        </div>
                        <div className='item row align-items-center pt-3'>
                            <div className='col-md-6'>
                                <div className='card-subtitle fs-6'>Total Amt.:</div>
                            </div>
                            <div className='col-md-6'>
                                <div className='text-end'>{EuroCurrency(total)} &euro;</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className='modal-footer'>
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    {isSubmitting ? 'Applying...' : 'Apply'}
                </button>
                <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={props.onHide}
                >
                    Close
                </button>
            </div>
            </form>
    )
}

export default AddEditDiscount