import React, { useEffect, useState } from 'react'
import { CallApi, fileUrl } from '../../utils';
import { useToast } from '../../redux/toastContext';

const LeadForms = (props) => {
  const [formData, setFormData] = useState({
      CompanyName:'',
      FirstName:'',
      LastName:'',
      StreetNo:'',
      PostalCodeCity:'',
      Country:'',
      Email:'',
      Phone:'',
      Mobile:'',
      VatId:'',
      Notes:'',
  })

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const { showToastMessage } = useToast();

  // set formdata data when input changed
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // set email data when email input change with email validating
  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setIsEmailValid(value ? validateEmail(value) : true);
  };

  // validate Email by regular expression
  const validateEmail = (email) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Reset form data
  const resetForm = () => {
    setFormData({
        CompanyName:'',
        FirstName:'',
        LastName:'',
        StreetNo:'',
        PostalCodeCity:'',
        Country:'',
        Email:'',
        Phone:'',
        Mobile:'',
        VatId:'',
        Notes:'',
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    // check form validity
    if (form.checkValidity() === false) {
      // add form validator class to validate form
      form.classList.add("was-validated");
    }else{
      // Process form submission
      setIsSubmitting(true);
      //check if users props present then update user else create user
      let params = `${props.data ? `leads/?id=${props.data}` : 'leads'}`;

      const fd = new FormData();
      for (let key in formData) {
          if (formData.hasOwnProperty(key)) {
              fd.append(key, formData[key]);
          }
      }

      // submit form data to server using API with post method
      try {
          const responseData = await CallApi('POST','', params, fd);
          if(responseData.status){
            showToastMessage(`Lead ${props.data ? 'updated': 'created'}`,'success')
             // reset form data values
            resetForm();
            // pass data to onAddProduct() on home page
            props.onAddUser(props.data);
            
          }else if(responseData.message == 'EmailId already exist'){
            showToastMessage('EmailId already exists.','error')
            setIsEmailValid(false);
            // Get the userEmail element
            var targetElement = document.getElementById('Email');
            targetElement.focus();
            // Get the next element sibling
            var nextElement = targetElement.nextElementSibling;

            // Check if there is a next sibling element
            if (nextElement) {
              // You can now work with the next element (e.g., modify its content)
              nextElement.textContent = "EmailId already exists.";
            }
          }else if(responseData.message){
            showToastMessage(responseData.message,'error');
          }else{
            showToastMessage("There are some issues while submitting form.",'error');
          }
          // enable submit button again
          setIsSubmitting(false); 
      } catch (error) {
          console.error(error.response);
          showToastMessage('There are some issues while submitting form.','error')
          // enable submit button again
          setIsSubmitting(false);    
      }
    }
  }

  const getLeadData = async (id) => {

    //get the user data by their id
    let params = `leads/?id=${id}`;
    try {
      const responseData = await CallApi('GET',null, params);

      if(responseData.status){
        const leadData = responseData.data;
        setFormData((prevData) => (
          { ...prevData, 
            ['CompanyName']: leadData.company_name ? leadData.company_name : '',
            ['FirstName']: leadData.firstname ? leadData.firstname : '',
            ['LastName']: leadData.lastname ? leadData.lastname: '',
            ['StreetNo']: leadData.street_no ? leadData.street_no : '',
            ['PostalCodeCity']: leadData.postal_code_city ? leadData.postal_code_city: '',
            ['Country']: leadData.country ? leadData.country : '',
            ['Email']: leadData.email ? leadData.email : '',
            ['Phone']: leadData.phone ? leadData.phone : '',
            ['Mobile']: leadData.mobile_phone ? leadData.mobile_phone : '',
            ['VatId']: leadData.vat_id ? leadData.vat_id : '',
            ['Notes']: leadData.notes ? leadData.notes: '',
          }
        ));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleImageChange = (event) => {
    const { name } = event.target;
    const value = event.target.files[0]
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  let initialized = false
  useEffect(() => {
    if(props.data && !initialized){
      initialized = true
      getLeadData(props.data);
    }
  }, [props.data])
  

  return (
    <div className="row">
      <div className="col-12 col-md-12 col-lg-12">
        <div className='card'>
          <form onSubmit={handleSubmit} className="needs-validation" noValidate autoComplete='off'>
            <div className="card-header">
              <h4>{props.data ? 'Edit' : 'Add'} Lead</h4>
              <div className='card-header-action' onClick={() => props.onAddUser('back')}>
                <button type="button" className='btn btn-secondary'>
                  Back
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label>Company Name</label>
                <input type="text" id="CompanyName" name="CompanyName" className="form-control" required autoComplete='off' value={formData.CompanyName}  onChange={handleInputChange}/>
                <div className="invalid-feedback">
                  Company Name is required
                </div>
              </div>
              <div className="form-group">
                <label>First Name</label>
                <input type="text" id="FirstName" name="FirstName" className="form-control" required autoComplete='off' value={formData.FirstName}  onChange={handleInputChange}/>
                <div className="invalid-feedback">
                  First Name is required
                </div>
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input type="text" id="LastName" name="LastName" className="form-control" autoComplete='off' value={formData.LastName}  onChange={handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Street / No.</label>
                <input type="text" id="StreetNo" name="StreetNo" className="form-control" autoComplete='off' value={formData.StreetNo}  onChange={handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Postal Code / City</label>
                <input type="text" id="PostalCodeCity" name="PostalCodeCity" className="form-control" autoComplete='off' value={formData.PostalCodeCity}  onChange={handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Country</label>
                <input type="text" id="Country" name="Country" className="form-control" autoComplete='off' value={formData.Country}  onChange={handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Email</label>
                <input type="email" name="Email" id="Email" className={`form-control ${isEmailValid ? '' : 'is-invalid'}`} autoComplete='off' value={formData.Email}  onChange={handleEmailChange}/>
                <div className="invalid-feedback">
                  Enter a valid Email Address
                </div>
              </div>
              <div className="form-group">
                <label>Phone</label>
                <input type="text" name="Phone" id="Phone" className="form-control" autoComplete='off' value={formData.Phone}  onChange={handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Mobile</label>
                <input type="text" name="Mobile" id="Mobile" className="form-control" autoComplete='off' value={formData.Mobile}  onChange={handleInputChange}/>
              </div>
              <div className="form-group">
                <label>VAT ID</label>
                <input type="text" name="VatId" id="VatId" className="form-control" autoComplete='off' value={formData.VatId}  onChange={handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Notes</label>
                <textarea name="Notes" id="Notes" rows={5} className="form-control" autoComplete='off' value={formData.Notes}  onChange={handleInputChange}></textarea>
              </div>
            </div>
            <div className="card-footer text-right">
              <button className="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default LeadForms;