import React, { useState } from 'react'
import CompanyList from './CompanyList';
import CompanyForms from './CompanyForm';

const Companies = () => {
    const [content, setContent] = useState('companylist');
    const [data, setData] = useState('');
    const handleContent = (content, data) => {
        setContent(content);
        setData(data);
    }
    return (
        <div className="main-content">
            <section className="section">
                <div className="section-header">
                    <h1>Companies</h1>
                </div>
                <div className="section-body">
                    {content == 'companylist' ?
                        <CompanyList addUser={(data) => handleContent('companyform',data)}/>
                        : <CompanyForms onAddUser={(data) => handleContent('companylist',data)} data={data}/>
                    }
                </div>
            </section>
        </div>
    )
}

export default Companies