import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

const Pagination = (props) => {

    const renderPageItem = (page) => {
        const isActive = page === props.currentPage;
        return (
          <li className={`page-item ${isActive ? 'active' : ''}`} key={page}>
            <button className="page-link" onClick={() => props.onPageChange(page)}>
              {page}
            </button>
          </li>
        );
      };

    const renderPaginationItems = () => {
        const items = [];

        if (props.lastPage <= 1) {
            return items;
        }

        // Previous page button
        items.push(
            <li className={`page-item ${props.currentPage === 1 ? 'disabled' : ''}`} key="prev">
                <button className="page-link" onClick={() => props.onPageChange(props.currentPage - 1)}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
            </li>
        );

        // Page numbers
        for (let page = 1; page <= props.lastPage; page++) {
            items.push(renderPageItem(page));
        }

         // Next page button
        items.push(
            <li className={`page-item ${props.currentPage === props.lastPage ? 'disabled' : ''}`} key="next">
            <button className="page-link" onClick={() => props.onPageChange(props.currentPage + 1)}>
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
            </li>
        );

        return items;
    }

    return (
        <ul className='pagination mb-0'>
            {renderPaginationItems()}
        </ul>
    )
}

export default Pagination