import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import {Document,Page,Text,View,StyleSheet, PDFViewer, Image, Font} from "@react-pdf/renderer";
import { useToast } from '../../redux/toastContext';
import { CallApi, EuroCurrency, backendUrl, fileUrl } from '../../utils';

Font.register({family: 'Roboto-regular', src: 'Roboto-Regular.ttf'})
Font.register({family: 'Roboto-Bold', src: 'Roboto-Bold.ttf'})

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto-regular",
    backgroundColor: "#fff",
    padding: 60,
    paddingTop: 40,
    paddingBottom: 95,
    position: 'relative',
  },
  pageContainer: {
    position: 'relative',
    overflow: 'hidden'
  },
  header: {
    marginBottom: 10,
    textAlign: 'center',
  },
  footer: {
    borderTop: 1.5,
    borderTopColor: '#277795',
    borderBottom: 1,
    position: 'absolute',
    bottom: 20,
    left: 60,
    right: 60,
    textAlign: 'right',
    overflow: "hidden"
  },
  table: {
    display: 'table',
    width: '100%',
    // borderStyle: 'solid',
    // borderWidth: 1,
   
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableColHeader: {
    fontFamily: "Roboto-Bold",
    borderStyle: 'solid',
    borderBottomWidth: 2,
    borderTopWidth: 2,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    padding: 5,
    paddingTop: 5,
    paddingBottom: 10,
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 11
  },
  tableCol: {
    borderStyle: 'solid',
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    padding: 5,
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: 'left',
    fontSize: 10
  },
  subtotal: {
    marginTop: 10,
    textAlign: 'right',
  },
  logoContainer:{
    display: "flex",
    flexDirection: 'row',
    justifyContent: "flex-end",
    paddingBottom: 10
  },
  logo: {
    /*width: 160,*/
    maxWidth: 150,
    maxHeight: 70
  },
  companyInfo: {
    paddingTop: 25,
    paddingBottom: 10
  },
  companyInfoText: {
    color: '#277795',
    fontSize: 9
  },
  customerInfo:{
    width: 130,
    flexWrap: 'nowrap',
    paddingBottom: 30
  },
  customerInfoText1:{
    fontFamily: "Roboto-Bold",
    fontSize: 11,
    fontWeight: 'Bold',
    lineHeight: 1.3
  },
  customerInfoText2:{
    fontSize: 10,
    fontWeight: 'light',
    flexWrap:"nowrap",
    lineHeight: 1.3
  },
  offerDates: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'center',
    flexDirection: 'row',
    paddingBottom: 10
  },
  dateFlex:{
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'center',
    flexDirection: 'row',
    width: 160
  },
  dateTitle: {
    fontFamily: "Roboto-Bold",
    fontSize: 11,
    fontWeight: 'Bold',
    lineHeight: 1.3
  },
  dateValue: {
    fontSize: 10,
    lineHeight: 1.3
  },
  OfferNo: {
    fontSize: 20,
    paddingBottom: 17
  },
  normalText: {
    fontSize: 10,
    paddingBottom: 20
  },
  finalizeQutes: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "row",
    fontSize: 11
  },
  productName:{
    fontFamily: "Roboto-Bold",
    fontWeight: "Bold"
  }
});

const GeneratePdf = ({ data }) => {
    const location = useLocation();
    const { showToastMessage } = useToast();
    const [selectedCards, setSelectedCards] = useState([]);
    const [company, setCompany] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [leadInfo, setLeadInfo] = useState(null);
    const [logoUrl, setLogoUrl] = useState(null);
    const [signature, setSignature] = useState(null);
    const [discountedPrice, setDiscountedPrice] = useState(null);

    const getOrderData = async (orderId) => {
      const params = `orderhistory?id=${orderId}`;
      try {
          const responseData = await CallApi('GET','',params);
          
          if(responseData.data){
            setOrderData(responseData.data)
            let resData = responseData.data;
            getCompany(resData.company_id)
            getLeadData(resData.lead_id)
            setDiscountedPrice(resData.discount_type == "0" ? parseFloat(resData.subtotal) * parseFloat(resData.discount_value)/100 : parseFloat(resData.discount_value));
          }
      } catch (error) {
          showToastMessage(`Somethign went wrong. Action not performed.`,'error');
      }
    }

    const getCards = async (orderId) => {
        const params = `sales/allCards?orderId=${orderId}`;
        try {
            const responseData = await CallApi('GET','',params);
            
            if(responseData.data && responseData.data.length > 0 ){
              setSelectedCards(responseData.data.filter(item => item.in_sales === 1))
            }
        } catch (error) {
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');
        }
    }

    const getCompany = async (companyId) => {
        const params = `companies?id=${companyId}`;
        try {
            const responseData = await CallApi('GET','',params);
            if(responseData.data){
              let data = responseData.data
              setCompany(responseData.data)
              if(data.company_logo){
                fetchLogo(fileUrl+`${data.company_logo}`, 'logo');
              }
              if(data.company_signature){
                fetchLogo(fileUrl+`${data.company_signature}`, 'signature');
              } 
            }
        } catch (error) {
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');
        }
    }

    const fetchLogo = (imageUrl, type) => { 
      
      const proxyUrl = `${backendUrl}proxy.php?url=${imageUrl}`;
    
      fetch(proxyUrl)
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          throw new Error('Network response was not ok.');
        })
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          if(type == 'logo'){
            setLogoUrl(blobUrl);
          }
          if(type == 'signature'){
            setSignature(blobUrl)
          }
        })
        .catch((error) => {
          console.error('Error fetching image:', error);
        });
    }

    const getLeadData = async (leadId) => { 
      const params = `leads/?id=${leadId}`;
      
      try {
          const responseData = await CallApi('GET',showToastMessage,params);
          if(responseData.data){
              setLeadInfo(responseData.data)
          }
      } catch (error) {
          showToastMessage(`Somethign went wrong. Action not performed.`,'error');
      }
    }

    const formateDate = (dateStr) => { 
      // Convert the date string to a Date object
      const date = dateStr ? new Date(dateStr) : new Date();

      // Array of month names
      const monthNames = [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];

      // Format the date as DD.MM.YYYY
      // Get the day, month, and year components
      const day = date.getDate().toString().padStart(2, '0');
      const monthIndex = date.getMonth();
      // Get the month name in character format
       const month = monthNames[monthIndex];
      const year = date.getFullYear();

      // Format the date as DD.MM.YYYY
      const formattedDate = `${day}.${month}.${year}`;
      
      return formattedDate;
    } 

    // Function to format the value without unnecessary trailing zeros
    const formatValue = (value) => {
      if (typeof value !== 'number') {
        value = parseFloat(value);
      }
      return value.toFixed(2).replace(/\.0+$/, '');
    };

    let initialized = false
    useEffect(() => {
      if (!initialized) { 
        initialized = true
       
        const params = new URLSearchParams(window.location.search);
        const dataParam = params.get('data');
        getOrderData(dataParam)
        getCards(dataParam)
       
        document.getElementsByClassName('navbar-bg')[0].style.display = 'none';
        document.getElementsByClassName('navbar')[0].style.display = 'none';
        document.getElementsByClassName('main-sidebar')[0].style.display = 'none';
      }
    }, [])
    
    return (
      <PDFViewer width="100%" style={{height:`100vh`}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.pageContainer}>
              {company.company_logo ? <View style={[styles.logoContainer]} fixed>
                <View style={[styles.logo]}>
                  <Image src={company.company_logo ? logoUrl : 'logo.png'}/>
                </View>
              </View>: '' }
              <View style={styles.companyInfo}>
                  <Text style={styles.companyInfoText}>{company?.company_name ?? ''} {company.street_no ? `| ${company.street_no}`: ''} {company.postal_code_city ? `| ${company.postal_code_city}` : ''}</Text>
              </View>
              <View style={styles.customerInfo}>
                  <Text style={styles.customerInfoText1}>{leadInfo?.company_name ?? ''}</Text>
                  <Text style={styles.customerInfoText2}>{leadInfo?.firstname ?? ''} {leadInfo?.lastname ?? ''}</Text>
                  <Text style={styles.customerInfoText2}>{leadInfo?.street_no ?? ''}</Text>
                  <Text style={styles.customerInfoText2}>{leadInfo?.postal_code_city ?? ''}</Text>
              </View>
              <View style={styles.offerDates}>
                  <View style={styles.dateFlex}>
                    <View style={styles.offerDate}>
                      <Text style={styles.dateTitle}>Zusammenfassung Datum</Text>
                      <Text style={styles.dateValue}>{formateDate(orderData?.order_date ?? '')}</Text>
                    </View>
                    {/* Temporarily commente as we are not using it now*/}
                    {/*<View style={styles.offerDate2}>
                      <Text style={styles.dateTitle}>Gültig bis</Text>
                      <Text style={styles.dateValue}>DD.MM.YYYY</Text>
                    </View>*/}
                  </View> 
              </View>
              <View style={styles.OfferNo}>
                <Text>Zusammenfassung Nr. {orderData?.order_number ?? ''}</Text>
              </View>
              <View style={styles.normalText}>
                <Text>Wir bedanken uns für Ihre Anfrage. Gerne unterbreiten wir Ihnen hiermit folgendes Zusammenfassung:</Text>
              </View>
              <View style={styles.table}>
                <View style={[styles.tableRow,{marginBottom: 7}]} fixed>
                  <Text style={[styles.tableColHeader,{width: '7%'}]}>Pos.</Text>
                  <Text style={[styles.tableColHeader,{width: '40%'}]}>Bezeichnung</Text>
                  <Text style={[styles.tableColHeader,{width: '12%'}]}>Menge</Text>
                  <Text style={[styles.tableColHeader,{width: '25%',textAlign: 'right'}]}>Einzel (€)</Text>
                  <Text style={[styles.tableColHeader,{width: '15%',textAlign: 'right'}]}>Gesamt (€)</Text>
                </View>
                {selectedCards.map((product, index) => {
                  return(
                    <View wrap={false} key={index}>
                      <View key={index} style={[styles.tableRow,{marginBottom: 3}]} wrap="none">
                        <Text style={[styles.tableCol,{width: '7%'}]}>{index + 1}</Text>
                        <View style={[styles.tableCol,{width: '40%'}]} >
                          <Text style={styles.productName}>{product.card_title}</Text>
                          <Text style={{fontSize: 8}}>{product.card_desc}</Text>
                        </View>
                        <Text style={[styles.tableCol,{width: '12%'}]}>Pauschal {/* or 1 Stück */}</Text>
                        <Text style={[styles.tableCol,{width: '25%',textAlign: 'right'}]}>{EuroCurrency(product.card_price)} &euro;</Text>
                        <Text style={[styles.tableCol,{width: '15%',textAlign: 'right'}]}>{EuroCurrency(product.card_price)} &euro;</Text>
                      </View>
                    </View>
                  )
                })}
              </View>
              <View wrap={false}>
                <View style={[styles.tableRow,{paddingTop: 10}]}>
                  <Text style={[styles.tableCol,{width: '7%'}]}></Text>
                  <Text style={[styles.tableCol,{width: '40%'}]}></Text>
                  <Text style={[styles.tableCol,{width: '12%'}]}></Text>
                  <Text style={[styles.tableCol,{width: '25%',textAlign: 'right'}]}>Summe netto</Text>
                  <Text style={[styles.tableCol,{width: '15%',textAlign: 'right'}]}>{EuroCurrency(orderData.subtotal)} &euro;</Text>
                </View>
                <View style={[styles.tableRow,{paddingBottom: 10, lineHeight: 0}]}>
                  <Text style={[styles.tableCol,{width: '7%'}]}></Text>
                  <Text style={[styles.tableCol,{width: '40%'}]}></Text>
                  <Text style={[styles.tableCol,{width: '12%'}]}></Text>
                  {orderData.discount_value ? <Text style={[styles.tableCol,{width: '25%',textAlign: 'right'}]}>{orderData.discount_type == '0' ? `Rabatt ${EuroCurrency(formatValue(orderData.discount_value))}%`:'Rabattbetrag'}</Text> : ''}
                  {orderData.discount_value ? <Text style={[styles.tableCol,{width: '15%',textAlign: 'right'}]}>{EuroCurrency(discountedPrice)} &euro;</Text> : ''}
                </View>
                <View style={[styles.tableRow]}>
                  <Text style={[styles.tableCol,{width: '7%'}]}></Text>
                  <Text style={[styles.tableCol,{width: '40%'}]}></Text>
                  <Text style={[styles.tableCol,{width: '12%'}]}></Text>
                  <Text style={[styles.tableCol,{width: '25%',textAlign: 'right', paddingBottom: 10,  paddingTop: 5}]}>
                    Umsatzsteuer {EuroCurrency(19)}%
                  </Text>
                  <Text style={[styles.tableCol,{width: '15%',textAlign: 'right', paddingBottom: 10,  paddingTop: 5}]}>
                    {EuroCurrency(orderData.vat)} &euro;
                  </Text>
                </View>
                <View style={[styles.tableRow]}>
                  <Text style={[styles.tableCol,{width: '7%'}]}></Text>
                  <Text style={[styles.tableCol,{width: '40%'}]}></Text>
                  <Text style={[styles.tableCol,{width: '12%'}]}></Text>
                  <Text style={[styles.tableCol,{width: '25%',textAlign: 'right',borderTop: 1, borderBottom: 1, paddingTop: 5, borderBottomColor: '#277795', fontWeight: 'bold', fontFamily: "Roboto-Bold", paddingBottom: 5}]}>
                    Rechnungsbetrag
                  </Text>
                  <Text style={[styles.tableCol,{width: '15%',textAlign: 'right',borderTop: 1, borderBottom: 1, paddingTop: 5, borderBottomColor: '#277795', fontWeight: 'bold', fontFamily: "Roboto-Bold", paddingBottom: 5}]}>
                    {EuroCurrency(orderData.total)} &euro;
                  </Text>
                </View>
              </View>
              <View wrap={false}>
                {orderData.note ?
                <View style={[styles.normalText,{paddingTop: 5, paddingBottom: 5}]}>
                    <Text><Text style={{fontFamily: "Roboto-Bold",fontWeight: "Bold"}}>Anmerkungen : </Text>{orderData.note}</Text>
                </View> : ''}
                {orderData.order_description ? 
                  <View style={[styles.normalText,{paddingTop: 5}]}>
                    <Text>{orderData.order_description}</Text>
                  </View> : ''
                }
                <View style={[styles.regards,{paddingTop: orderData.note || orderData.order_description ? 0 : 20}]} wrap={false}>
                    <Text style={{fontSize: 10}}>Mit freundlichen Grüßen</Text>
                    {company?.company_signature ? <Image src={company.company_signature ? signature : 'Screenshot_2.png'} style={{height: 'auto', width: 100}}/> : <Text style={{fontSize: 15,color: '#277795'}}>{company?.company_name ?? ''}</Text>}
                </View>
              </View>
            </View>
            <View style={styles.footer} fixed>
              <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingTop: 5, paddingBottom: 10}}>
                <View style={{fontSize: 8}}>
                  <Text>{company?.company_name ?? ''}</Text>
                  {company.street_no ? <Text>{company.street_no}</Text> : ""}
                  {company.postal_code_city ? <Text>{company.postal_code_city}</Text> : ''}
                  {company.vat_id ? <Text>Ust-IdNr: {company.vat_id}</Text>: ""}
                </View>
                <View style={{fontSize: 8}}>
                  {company.phone != '' ? <Text>Phone: {company.phone}</Text> : ''}
                  {company.fax != '' ? <Text>Fax: {company.fax}</Text> : ''}
                  {company.email != '' ? <Text>E-Mail: {company.email}</Text> : ''}
                  {company.website != '' ? <Text>Web: {company?.website}</Text> : ''}
                </View>
                <View style={{fontSize: 8}}>
                  <Text>Bank Details</Text>
                  {company.bank_name ? <Text>{company.bank_name}</Text>: '' }
                  {company.IBAN ? <Text>IBAN: {company.IBAN}</Text>: ''}
                  {company.BIC ? <Text>BIC: {company.BIC}</Text>: ''}
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    );
}

export default GeneratePdf