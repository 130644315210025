import React from 'react'

const Dashboard = () => {
  return (
    <div className="main-content">
        <section className="section">
            <div className="section-header">
                <h1>Dashboard</h1>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <h1><center>Coming Soon</center></h1>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Dashboard