import { faBuildingUser, faDashboard, faListCheck, faListSquares, faSuitcase, faUserAlt, faUsers, faUsersBetweenLines } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'

const Sidebar = () => {
  return (
    <div className="main-sidebar">
        <aside id="sidebar-wrapper">
          <div className="sidebar-brand">   
            <Link to="/" > Offer Creator </Link>
          </div>
          <div className="sidebar-brand sidebar-brand-sm">
            <Link to="/"> OC </Link>
          </div>
          <ul className='sidebar-menu mb-5'>
            <li>
              <NavLink to="/" exact="true">
                <FontAwesomeIcon icon={faDashboard} fontSize={15}/>
                <span>Dashboard </span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/companies" exact="true">
                <FontAwesomeIcon icon={faBuildingUser} fontSize={15}/>
                <span>Companies </span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/offers" exact="true">
                <FontAwesomeIcon icon={faListCheck} fontSize={15}/>
                <span>Offers </span>
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/users" exact="true">
                <FontAwesomeIcon icon={faUsers} fontSize={15}/>
                <span>Users </span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/leads" exact="true">
                <FontAwesomeIcon icon={faUsersBetweenLines} fontSize={15}/>
                <span>Leads </span>
              </NavLink>
            </li>
             <li>
              <NavLink to="/packages" exact="true">
                <FontAwesomeIcon icon={faSuitcase} fontSize={15}/>
                <span>Packages </span>
              </NavLink>
            </li>
            
          </ul>
        </aside>
    </div>
  )
}

export default Sidebar