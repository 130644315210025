import React, { useEffect, useRef, useState } from 'react'
import { CallApi } from '../utils';

const AddEditProductModal = (props) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [image, setImage] = useState(null);
    const [url, setURL] = useState("");
    const [order, setOrder] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const modalRef = useRef(null);
    const $modal = window.$(modalRef.current);
    const formRef = useRef(null);

    const resetForm = () => {
      setTitle("");
      setDescription("");
      setPrice("");
      setImage(null);
      setURL("");
      // reset form value using form reference for image selection reset
      formRef.current.reset();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        // check form validity
        if (form.checkValidity() === false) {
            // add form validator class to validate form
            form.classList.add("was-validated");
        }else{
            
            // Process form submission
            setIsSubmitting(true);
            //Create formData
            const formData = new FormData();
            formData.append('card_title', title);
            formData.append('card_desc', description);
            formData.append('card_price', Number(price));
            formData.append('card_state', '0');
            formData.append('card_used', '0');
            formData.append('card_url', url);
            formData.append('card_order', order);

            // check if image selected then upload image
            if(image){
              formData.append('card_image', image); // where avatarFile is a File object representing the image
            }
            
            //check if products props present then update the product else create then product url
            let params = `${props.product != '' ? `products/?id=${props.product.card_id}` : 'products'}`;

            try {
                const responseData = await CallApi('POST',props.showToastMessage, params, formData);
                // enable submit button again
                setIsSubmitting(false); 
                // reset form data values
                resetForm();
                // pass data to onAddProduct() on home page
                props.onAddProduct(responseData);
                
                    
            } catch (error) {
                // enable submit button again
                setIsSubmitting(false); 
                console.error(error);
                // reset form data values
                resetForm();
                // pass error to onAddProduct() on home page
                props.onAddProduct(error)
            }

            $modal.modal("hide");
            // Hide the modal  
            // $modal.on('hidden.bs.modal', props.onHide);
            props.onHide();
        }
    };
  
    const handleImageChange = (event) => {
      setImage(event.target.files[0]);
    };

    useEffect(() => {
        if (props.show) {
            if(props.product != ''){
                setTitle(props.product.card_title);
                setDescription(props.product.card_desc);
                setPrice(props.product.card_price);
                setURL(props.product.card_url ? props.product.card_url : '');
                setOrder(props.product.card_order ? props.product.card_order : 0);
            }else{
                // reset form data values
                resetForm();

                if(props.nextOrderId){
                  setOrder(props.nextOrderId);
                }
            }
            $modal.modal("show");
        } else {
            if (formRef.current.classList.contains('was-validated')) {
                formRef.current.classList.remove('was-validated')
            }
            // reset form data values
            resetForm();
            $modal.on("hidden.bs.modal", props.onHide);
        }
        return () => {
            $modal.off("hidden.bs.modal");
        };
      }, [props.show,props.product]);
  
    return (
      <div
        className="modal fade"
        id="addProductModal"
        tabIndex="-1"
        aria-labelledby="addProductModalLabel"
        aria-hidden="true"
        role="dialog"
        ref={modalRef}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addProductModalLabel">
              {props.product != '' ? 'Edit Product' : 'Add Product'}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={props.onHide}
              ></button>
            </div>
            <form onSubmit={handleSubmit} noValidate ref={formRef}>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    className="form-control"
                    id="description"
                    rows="3"
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    required
                  ></textarea>
                </div>
                <div className="mb-3">
                  <label htmlFor="price" className="form-label">
                    Price
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="price"
                    value={price}
                    onChange={(event) => setPrice(event.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="image" className="form-label">
                    Image
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="image"
                    accept=".jpg, .png"
                    onChange={handleImageChange}
                    required={props.product == '' ? true : false}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="url" className="form-label">
                    URL
                  </label>
                  <input
                    type="url"
                    className="form-control"
                    id="url"
                    value={url}
                    onChange={(event) => setURL(event.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="url" className="form-label">
                    Order
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="order"
                    value={order}
                    onChange={(event) => setOrder(event.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                  {isSubmitting ? 'Saving...' : 'Save'}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={props.onHide}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
}

export default AddEditProductModal