import { faArrowLeft, faCheck, faFilePdf, faMagnifyingGlass, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CallApi, EuroCurrency, getDateinFormate } from '../../utils';
import Pagination from '../../components/Pagination';
import ConfirmModal from '../../components/ConfirmModal';
import { useToast } from '../../redux/toastContext';

const HistoryList = (props) => {
    const [searchText, setSearchText] = useState('');
    const [orders, setOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [confirmModalData, setConfirmModalData] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const navigate = useNavigate();
    const { state } = useLocation();
    const { showToastMessage } = useToast();
    const leadId = state.leadId;
    let initialized = false
    useEffect(() => {
        if (!initialized) { 
            initialized = true
            getOrderHistory(searchText,1)
            if (isElementHidden(document.getElementsByClassName('navbar-bg')[0])) {
                document.getElementsByClassName('navbar-bg')[0].style.display = 'block';
            }
            if (isElementHidden(document.getElementsByClassName('navbar')[0])) {
                document.getElementsByClassName('navbar')[0].style.display = 'flex';
            }
            if (isElementHidden(document.getElementsByClassName('main-sidebar')[0])) {
                document.getElementsByClassName('main-sidebar')[0].style.display = 'block';
            }
        }
    }, [searchText])

    function isElementHidden(element) {
        const computedStyle = window.getComputedStyle(element);
        return computedStyle.display === 'none';
    }

    // function to get order history data
    const getOrderHistory = async (search,page) => {
        const params = search ? `orderhistory?leadId=${leadId}&search=${search}${page > 1 ? `&page=${page}`: ''}`: `orderhistory?leadId=${leadId}${page > 1 ? `&page=${page}` : ''}`;
        try {
            const responseData = await CallApi('GET','',params);
            if(responseData.status){
                let usersData = responseData.data.data;
                setOrders(usersData);
                let pagination = responseData.data.pagination;
                setCurrentPage(pagination.currentPage);
                setLastPage(pagination.lastPage);
            }
        } catch (error) {
            
            showToastMessage("Something went wrong while deleted lead",'error');
        }
    }

     // function to handle when the page change
     const handlePageChange = (page) => {
        setCurrentPage(page);
        getOrderHistory(searchText,page)
    }

    const handleDeleteOrder = (id) => {
        setShowConfirmModal(true);
        const confirmData = {
            "title":"Confirm Delete",
            "text": "Are you sure you want to delete this summary?",
            "id": id,
            "type":"user",
            "action":"delete"
        }
        setConfirmModalData(confirmData);
    }

      // function to handle whene modal confirm action performed
      const confirmModalAction = async (data) => {
        setShowConfirmModal(false);
        setConfirmModalData('');
        if(data?.action == 'delete'){
            try {
                const params = `orderhistory/?id=${data.id}`;
                const responseData = await CallApi('DELETE','',params);
                if(responseData.status){
                    getOrderHistory(searchText,1);
                    showToastMessage(`Order Deleted.`,'success');      
                }else{
                    showToastMessage("order is not deleted",'error')
                }                                                                                                           
            } catch (error) {            
                
                showToastMessage(`Somethign went wrong. Action not performed.`,'error');                      
            }
        }
        if(data?.action == 'markasfinal'){
            try {
                const params = `orderhistory/?id=${data.id}`;
                const formData = new FormData();
                formData.append('status', '2');
                const responseData = await CallApi('POST','',params,formData);
                if(responseData.status){
                    getOrderHistory(searchText,1);
                    showToastMessage(`Order marked as final.`,'success');      
                }else{
                    showToastMessage("order is not marked as final",'error')
                }                                                                                                           
            } catch (error) {            
                
                showToastMessage(`Somethign went wrong. Action not performed.`,'error');                      
            }
        }
        
    }

    const handleMarkFinal = (id) => { 
        setShowConfirmModal(true);
        const confirmData = {
            "title":"Confirm mark as final",
            "text": "Are you sure you want to mark this summary as final?",
            "id": id,
            "type":"user",
            "action":"markasfinal"
        }
        setConfirmModalData(confirmData);
    }

    const genearatePdf = (data) => { 
        window.open(`/generatepdf?data=${encodeURIComponent(data)}`, '_blank');
    }

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-header-form">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search" fdprocessedid="ijder" onChange={(e) => setSearchText(e.target.value)}/>
                                        <div className="input-group-btn">
                                            <button className="btn btn-primary" fdprocessedid="1higi">
                                                <FontAwesomeIcon icon={faMagnifyingGlass} style={{ fontWeight: 'bold', fontSize: "13px" }}/>
                                            </button>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className='table-responsive'>
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Order Number</th>
                                        <th scope="col">Value</th>
                                        <th scope="col">Order Date</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {orders.length > 0 ?
                                        orders.map((data, index) => {
                                            // Calculate the adjusted index based on the current page
                                            const adjustedIndex = (currentPage - 1) * 10 + index + 1;

                                            return(
                                            <tr key={index}>
                                                <th scope="row">{index+1}</th>
                                                <td>{data.order_number}</td>
                                                <td>{EuroCurrency(data.total)} &euro;</td>
                                                <td>{getDateinFormate(data.created_at)}</td>
                                                <td>
                                                    {data.status == '1' ? <button className='btn btn-sm btn-icon icon-left btn-primary me-2' onClick={() => props.editOrder(data)} title='Edit Order'>
                                                        <FontAwesomeIcon icon={faPen} fontSize={14}/>
                                                    </button>: ''}
                                                    {data.status == '1' ?
                                                    <button type='button' className='btn btn-sm btn-icon icon-left btn-success me-2' onClick={() => handleMarkFinal(data.id)} title='Mark as final'>
                                                        <FontAwesomeIcon icon={faCheck} fontSize={14}/>
                                                    </button>: ''}
                                                    <button type='button' className='btn btn-sm btn-icon icon-left btn-primary me-2' onClick={() => genearatePdf(data.id)} title='Print as PDF'>
                                                        <FontAwesomeIcon icon={faFilePdf} fontSize={14}/>
                                                    </button>
                                                    <button type='button' className='btn btn-sm btn-icon icon-left btn-danger' onClick={() => handleDeleteOrder(data.id)} title='Delete Order'>
                                                        <FontAwesomeIcon icon={faTrash} fontSize={14}/>
                                                    </button>
                                                </td>
                                            </tr>)
                                        })
                                        :
                                        <tr>
                                            <td colSpan="10"><center>No Records Found</center></td>
                                        </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        
                        </div>
                        <div className='card-footer text-end'>
                            <nav className='d-inline-block'>
                                <Pagination currentPage={currentPage} lastPage={lastPage} onPageChange={handlePageChange} />
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
             {/* Confirm modal to confirm action before it perform */}            
             <ConfirmModal show={showConfirmModal} data={confirmModalData} onConfirm={(data) => confirmModalAction(data)} setShowConfirmModal={(val) => setShowConfirmModal(val)}/>
        </>
    )
}

export default HistoryList