import React, { useState } from 'react'
import UsersList from './UsersList';
import UserForms from './UserForms';
import { CallApi } from '../../utils';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/auth/Actions';

const Users = () => {
    const [content, setContent] = useState('userslist');
    const [data, setData] = useState('');
    const dispatch = useDispatch();
    const handleContent = (content, data) => {
        setContent(content);
        setData(data);
        let currentUser = JSON.parse(localStorage.getItem('user'));
        currentUser = currentUser.userData;
        if(data == currentUser.id){
            getUserData(currentUser.id);
        }
    }

    const getUserData = async(id) => {

        //get the user data by their id
        let params = `users/?id=${id}`;
        try {
        const responseData = await CallApi('GET',null, params);

        if(responseData.status){
            const userData = responseData.data;
            let user = {"id":userData.id, "user_email": userData.user_email,"user_image":userData.user_image, "user_name":userData.user_name}
            const token = localStorage.getItem('token');
            dispatch(login(user, token));
        }
        } catch (error) {
        console.error(error);
        }
    }
    return (
        <div className="main-content">
            <section className="section">
                <div className="section-header">
                    <h1>Users</h1>
                </div>
                <div className="section-body">
                    {content == 'userslist' ?
                        <UsersList addUser={(data) => handleContent('userform',data)}/>
                        : <UserForms onAddUser={(data) => handleContent('userslist',data)} data={data}/>
                    }
                </div>
            </section>
        </div>
  )
}

export default Users