import React, { useEffect, useRef } from 'react'

const ConfirmModal = (props) => {
    const modalRef = useRef(null);
    const $modal = window.$(modalRef.current);

    const hideModal = () => {
        props.setShowConfirmModal(false)
        $modal.modal("hide");
    }

    const confirmAction = (data) => {
        props.onConfirm(data);
        $modal.modal("hide");
    }

    useEffect(() => {
        if (props.show) {
            $modal.modal("show");
        }else{
            $modal.on("hidden.bs.modal", props.onHide);
        }
        return () => {
            $modal.off("hidden.bs.modal");
        };
    },[props.show])
    return (
        <div className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true" tabIndex="-1" role="dialog" ref={modalRef}> 
            <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title">{props.data.title ? props.data.title : 'Confirm Action'}</h5>
                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={() => hideModal()}>
                </button>
                </div>
                <div className="modal-body">
                <p>{props.data.text ? props.data.text : 'Are you sure you want to perform this action?'}</p>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() =>  hideModal()}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={() => confirmAction(props.data)}>Confirm</button>
                </div>
            </div>
            </div>
        </div>
    )
}

export default ConfirmModal