import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt, faMagnifyingGlass, faPlusSquare, faSquarePlus, faUserAltSlash, faUserEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import ConfirmModal from '../../components/ConfirmModal';
import Pagination from '../../components/Pagination';
import { CallApi, getDateinFormate } from '../../utils';
import { useToast } from '../../redux/toastContext';

const CompanyList = (props) => {
    const [searchText, setSearchText] = useState('');
    const [companies, setCompanies] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [confirmModalData, setConfirmModalData] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const { showToastMessage } = useToast();
    let isLoaded = false
    useEffect(() => {
        if(!isLoaded){
            isLoaded = true;
            getCompaniesList(searchText,1);
        }
    }, [searchText])

    // function to get leads data
    const getCompaniesList = async (search,page) => {
        const params = search ? `companies/?search=${search}${page > 1 ? `&page=${page}`: ''}`: `companies${page > 1 ? `?page=${page}` : ''}`;
        try {
            const responseData = await CallApi('GET',showToastMessage,params);
            if(responseData.status){
                let companiesData = responseData.data.data;
                setCompanies(companiesData);
                let pagination = responseData.data.pagination;
                setCurrentPage(pagination.currentPage);
                setLastPage(pagination.lastPage);
            }
        } catch (error) {
            
        }
    }

    // Function to handle deleting product
    const handleDeleteCompanies =  (id) => {
        setShowConfirmModal(true);
        const confirmData = {
            "title":"Confirm Delete",
            "text": "Are you sure you want to delete this Company?",
            "id": id,
            "type":"user"
        }
        setConfirmModalData(confirmData);
    }

    // function to handle when the page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
        getCompaniesList(searchText,page)
    }

     // function to handle whene modal confirm action performed
     const confirmModalAction = async (data) => {
        setShowConfirmModal(false);
        setConfirmModalData('');
        try {
            const params = `companies/?id=${data.id}`;
            const responseData = await CallApi('DELETE','',params);
            if(responseData.status){
                showToastMessage(`Company Deleted.`,'success');                                                                                                              
                getCompaniesList(searchText,1)
            }else{
                showToastMessage("Company is not deleted",'error')
            }

        } catch (error) {
            
            showToastMessage("Something went wrong while deleted company",'error');
                            
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-form">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search" fdprocessedid="ijder" onChange={(e) => setSearchText(e.target.value)}/>
                                    <div className="input-group-btn">
                                        <button className="btn btn-primary" fdprocessedid="1higi">
                                            <FontAwesomeIcon icon={faMagnifyingGlass} style={{ fontWeight: 'bold', fontSize: "13px" }}/>
                                        </button>
                                    </div>
                                </div>
                        </div>
                        <div className='card-header-action'>
                            <button type="button" className='btn btn-icon icon-left btn-primary' onClick={() => props.addUser(null)}>
                                <FontAwesomeIcon icon={faPlusSquare} fontSize={14} className='me-2'/>
                                <span>Add Company</span>
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className='table-responsive'>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Company Name</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Created On</th>
                                    <th scope="col">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {companies.length > 0 ?
                                    companies.map((data, index) => {
                                        // Calculate the adjusted index based on the current page
                                        const adjustedIndex = (currentPage - 1) * 10 + index + 1;

                                        const address = [];
                                        
                                        if (data.street_no) {
                                            address.push(data.street_no);
                                        }
                                        
                                        if (data.postal_code_city) {
                                            address.push(data.postal_code_city);
                                        }
                                        
                                        if (data.country) {
                                            address.push(data.country);
                                        }
                                        return(
                                        <tr key={index}>
                                            <th scope="row">{adjustedIndex}</th>
                                            <td>{data.company_name}</td>
                                            <td>{address.join(', ')}</td>
                                            <td>{data.email}</td>
                                            <td>{getDateinFormate(data.created_at)}</td>
                                            <td>
                                                {/* <button type='button' className='btn btn-sm btn-icon icon-left btn-primary me-2' onClick={() => GotoOffer(data.id)} title='Create Offer'>
                                                    <FontAwesomeIcon icon={faSquarePlus} fontSize={14}/>
                                                </button>
                                                <button type='button' className='btn btn-sm btn-icon icon-left btn-primary me-2' onClick={() => GoToHistory(data.id, data.company_name)} title='Orders History'>
                                                    <FontAwesomeIcon icon={faListAlt} fontSize={14}/>
                                                </button> */}
                                                <button className='btn btn-sm btn-icon icon-left btn-primary me-2' onClick={() => props.addUser(data.id)} title='Edit Lead'>
                                                    <FontAwesomeIcon icon={faUserEdit} fontSize={14}/>
                                                </button>
                                                <button type='button' className='btn btn-sm btn-icon icon-left btn-danger ' onClick={() => handleDeleteCompanies(data.id)} title='Delete Lead'>
                                                    <FontAwesomeIcon icon={faUserAltSlash} fontSize={14}/>
                                                </button>
                                            </td>
                                        </tr>)
                                    })
                                    :
                                    <tr>
                                        <td colSpan="10"><center>No Records Found</center></td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    
                    </div>
                    <div className='card-footer text-end'>
                        <nav className='d-inline-block'>
                            <Pagination currentPage={currentPage} lastPage={lastPage} onPageChange={handlePageChange} />
                        </nav>
                    </div>
                </div>
                </div>
            </div>
            {/* Confirm modal to confirm action before it perform */}
            <ConfirmModal show={showConfirmModal} data={confirmModalData} onConfirm={(data) => confirmModalAction(data)} setShowConfirmModal={(val) => setShowConfirmModal(val)}/>
        </>
    )
}

export default CompanyList