import React, { useEffect, useRef } from 'react'

const Modal = (props) => {
    const modalRef = useRef(null);
    const $modal = window.$(modalRef.current);
    useEffect(() => {
        if (props.show) {
          $modal.modal('show');
        } else {
          $modal.modal('hide');
        }
      }, [props.show]);

    useEffect(() => {
        const modalElement = modalRef.current;
        const hideModalHandler = () => {
          props.onHide()
        };
    
        // Add event listener for 'hidden' event
        modalElement.addEventListener('hidden.bs.modal', hideModalHandler);
    
        // Clean up the event listener
        return () => {
          modalElement.removeEventListener('hidden.bs.modal', hideModalHandler);
        };
      }, []);
    return (
        <div
            className="modal fade"
            id="addProductModal"
            tabIndex="-1"
            aria-labelledby="addProductModalLabel"
            aria-hidden="true"
            role="dialog"
            ref={modalRef}
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.title}</h5>
                        <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={props.onHide}
                        ></button>
                    </div>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Modal