import Dashboard from "./screens/Dashboard";
import Home from "./screens/Home";
import Login from "./screens/Login";
import Offers from "./screens/Offers";
import Users from "./screens/Users";
import Leads from "./screens/Leads";
import Packages from "./screens/Packages";
import OrderHisory from "./screens/OrderHistory";
import Companies from "./screens/Companies";
import GeneratePdf from "./components/GeneratePdf";

const RouteList = [
    {
      path: '/',
      exact: true,
      component: <Dashboard />,
    },
    {
      path: '/offers',
      exact: true,
      component: <Home />,
    },
    {
      path: '/users',
      exact: true,
      component: <Users />,
    },
    {
      path: '/leads',
      exact: true,
      component: <Leads />,
    },
    {
      path: '/packages',
      exact: true,
      component: <Packages />,
    },
    {
      path: '/angebot-anze',
      exact: true,
      component: <Offers/>,
    },
    {
      path: '/orders-history',
      exact: true,
      component: <OrderHisory/>,
    },
    {
      path: '/login',
      exact: true,
      component: <Login/>,
    },
    {
      path: '/companies',
      exact: true,
      component: <Companies/>,
    },
    {
      path: '/generatepdf',
      exact: true,
      component: <GeneratePdf/>,
    }
  ];

export default RouteList;