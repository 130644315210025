import React,{ createContext, useState } from "react";

export const FullscreenContext = createContext();

export const FullscreenProvider = ({ children }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen  = (value) => {
    setIsFullscreen(value);
    var body = document.querySelector('body');
    if(value){
      body.classList.add('fullscreen-mode');
    }else{
      body.classList.remove('fullscreen-mode');
    }
  }

  return (
    <FullscreenContext.Provider value={{ isFullscreen, toggleFullscreen }}>
      {children}
    </FullscreenContext.Provider>
  );
};