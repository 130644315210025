import React, { useEffect, useState } from 'react'
import { CallApi, fileUrl } from '../../utils';
import { useToast } from '../../redux/toastContext';

const UserForms = (props) => {
  const [formData, setFormData] = useState({
      userName:'',
      userEmail:'',
      userImage:null,
      userPassword:'',
      userStatus:0,
  })

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [img, setImg] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const { showToastMessage } = useToast();

  // set formdata data when input changed
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // set email data when email input change with email validating
  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setIsEmailValid(value ? validateEmail(value) : true);
  };

  // validate Email by regular expression
  const validateEmail = (email) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // handle userStatus change
  const handleCheckBoxChange = (e) => {
    const { checked } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      userStatus: checked ? 1 : 0
    }));
  }

  // Reset form data
  const resetForm = () => {
    setFormData({
      userName:'',
      userEmail:'',
      userImage:null,
      userPassword:'',
      userStatus:0,
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    // check form validity
    if (form.checkValidity() === false) {
      // add form validator class to validate form
      form.classList.add("was-validated");
    }else{
      // Process form submission
      setIsSubmitting(true);
      //check if users props present then update user else create user
      let params = `${props.data ? `users/?id=${props.data}` : 'users'}`;

      const fd = new FormData();
      for (let key in formData) {
          if (formData.hasOwnProperty(key)) {
              fd.append(key, formData[key]);
          }
      }

      // submit form data to server using API with post method
      try {
          const responseData = await CallApi('POST','', params, fd);
          if(responseData.status){
            showToastMessage(`User ${props.data ? 'updated': 'created'}`,'success')
             // reset form data values
            resetForm();
            // pass data to onAddProduct() on home page
            props.onAddUser(props.data);
            
          }else if(responseData.message == 'Email already exist'){
            showToastMessage('EmailId already exists.','error')
            setIsEmailValid(false);
            // Get the userEmail element
            var targetElement = document.getElementById('userEmail');
            targetElement.focus();
            // Get the next element sibling
            var nextElement = targetElement.nextElementSibling;

            // Check if there is a next sibling element
            if (nextElement) {
              // You can now work with the next element (e.g., modify its content)
              nextElement.textContent = "EmailId already exists.";
            }
          }else{
            showToastMessage("There are some issues while submitting form",'error');
          }
          // enable submit button again
          setIsSubmitting(false); 
      } catch (error) {
          console.error(error);
          showToastMessage('There are some issues while submitting form.','error')
          // reset form data values
          resetForm();
          // pass error to onAddProduct() on home page
          // props.onAddUser();
          // enable submit button again
          setIsSubmitting(false);    
      }
    }
  }

  const getUserData = async (id) => {

    //get the user data by their id
    let params = `users/?id=${id}`;
    try {
      const responseData = await CallApi('GET',null, params);

      if(responseData.status){
        const userData = responseData.data;
        setFormData((prevData) => (
          { ...prevData, 
            ['userName']: userData.user_name,
            ['userEmail']:userData.user_email,
            ['userStatus']:userData.user_status == "0" ? 0 : 1,
          }
        ));
        setImg(userData.user_image)
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleImageChange = (event) => {
    const { name } = event.target;
    const value = event.target.files[0]
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  let initialized = false
  useEffect(() => {
    if(props.data && !initialized){
      initialized = true
      getUserData(props.data);
    }
  }, [props.data])
  

  return (
    <div className="row">
      <div className="col-12 col-md-12 col-lg-12">
        <div className='card'>
          <form onSubmit={handleSubmit} className="needs-validation" noValidate autoComplete='off'>
            <div className="card-header">
              <h4>{props.data ? 'Edit' : 'Add'} User</h4>
              <div className='card-header-action' onClick={() => props.onAddUser('back')}>
                <button type="button" className='btn btn-secondary'>
                  Back
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label>User Name</label>
                <input type="text" id="userName" name="userName" className="form-control" required autoComplete='off' value={formData.userName}  onChange={handleInputChange}/>
                <div className="invalid-feedback">
                  User Name is required
                </div>
              </div>
              <div className="form-group">
                <label>Email</label>
                <input type="email" name="userEmail" id="userEmail" className={`form-control ${isEmailValid ? '' : 'is-invalid'}`} required autoComplete='off' value={formData.userEmail}  onChange={handleEmailChange}/>
                <div className="invalid-feedback">
                  Email is required
                </div>
              </div>
              <div className="form-group">
                <label>Password</label>
                <input type="password" name="userPassword" id="userPassword" className="form-control" required={!props.data} autoComplete='off' value={formData.userPassword} minLength={5} onChange={handleInputChange}/>
                <div className="invalid-feedback">
                  Min 5 character required for password
                </div>
              </div>
              <div className="form-group d-flex align-items-center justify-content-between">
                <div className='flex-grow-1'>
                  <label>Image</label>
                  <input type="file" className="form-control" name="userImage" id="userImage" accept=".jpg, .png" onChange={handleImageChange} />
                  <div className="invalid-feedback">
                    Image is required
                  </div>
                </div>
                {img && <div className='ms-5'>
                  <img src={fileUrl+img} className='form-img my-2 rounded-2'/>
                </div>}
              </div>
              <div className="form-group mb-0">
                <div className="control-label">User Status</div>
                <label className="custom-switch mt-2">
                  <input
                    type="checkbox"
                    name="userStatus"
                    className="custom-switch-input"
                    id="userStatus"
                    checked={formData.userStatus === 1}
                    onChange={handleCheckBoxChange}
                  />
                  <span className="custom-switch-indicator"></span>
                </label>
              </div>
            </div>
            <div className="card-footer text-right">
              <button className="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default UserForms;