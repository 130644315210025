import React, { useContext, useState } from 'react'
import PackagesList from './PackagesList';
import PackageOffers from './PackageOffers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faExpand } from '@fortawesome/free-solid-svg-icons';
import { FullscreenContext } from '../../redux/fullScreenContext';

const Packages = () => {

    const [content, setContent] = useState('packageslist');
    const [data, setData] = useState('');
    const [title, setTitle] = useState('Packages');

    const { isFullscreen,toggleFullscreen } = useContext(FullscreenContext);

    const handleContent = (content, data) => {
        setContent(content);
        setData(data);
        if(content == 'packageOffer'){
            setTitle("Package Offers");
        }else{
            setTitle("Packages");
        }
    }
    return (
        <div className="main-content">
            <section className="section">
                {!isFullscreen && (
                    <div className="section-header justify-content-between">
                    <h1>{title}</h1>
                    {content == 'packageOffer' &&
                    <div className='btn-grp'>
                    <button className='btn bt-icon' title="Back" onClick={() => handleContent('packageslist','')} >
                        <FontAwesomeIcon icon={faArrowLeft} fontSize={20} className='align-middle'/>
                    </button>
                    <button
                        className="btn bt-icon fullscreenmode-btn"
                        title="fullscreen"
                        onClick={() => toggleFullscreen(true)}
                    >
                        <FontAwesomeIcon
                        icon={faExpand}
                        fontSize={20}
                        className="align-middle"
                        />
                    </button>
                    </div>}
                    </div>
                )}
                <div className="section-body">
                    {content == 'packageslist' ?
                        <PackagesList addPackage={(data) => handleContent('packageOffer',data)}/>
                        : <PackageOffers onAddPackage={(data) => handleContent('packageslist',data)} data={data}/>
                    }
                </div>
            </section>
        </div>
    )
}

export default Packages