import { faChevronLeft, faChevronRight, faMagnifyingGlass, faUserAltSlash, faUserEdit, faUserMinus, faUserPen, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { CallApi, fileUrl, getDateinFormate} from '../../utils';
import ConfirmModal from '../../components/ConfirmModal';
import avatar1 from '../../assets/avatar/avatar-1.png';
import Pagination from '../../components/Pagination';
import { useToast } from '../../redux/toastContext';

const UsersList = (props) => {
    const [searchText, setSearchText] = useState('');
    const [users, setUsers] = useState([]);
    const [confirmModalData, setConfirmModalData] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const { showToastMessage } = useToast();

    let initialized = false
    // function to get users data
    const getUsers = async (search,page) => {
        const params = search ? `users/?search=${search}${page > 1 ? `&page=${page}`: ''}`: `users${page > 1 ? `?page=${page}` : ''}`;
        try {
            const responseData = await CallApi('GET','',params);
            if(responseData.status){
                let usersData = responseData.data.data;
                setUsers(usersData);
                let pagination = responseData.data.pagination;
                setCurrentPage(pagination.currentPage);
                setLastPage(pagination.lastPage);
            }
        } catch (error) {
            
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');  
        }
    }

    useEffect(() => {
        if (!initialized) { 
            initialized = true
            getUsers(searchText,1)
        }
    }, [searchText])

    const handlePageChange = (page) => {
        setCurrentPage(page);
        getUsers(searchText,page)
    };

    // Function to handle deleting product
    const handleDeleteProduct =  (id) => {
        setShowConfirmModal(true);
        const confirmData = {
            "title":"Confirm Delete",
            "text": "Are you sure you want to delete this user?",
            "id": id,
            "type":"user"
        }
        setConfirmModalData(confirmData);
    }
    
    // function to handle whene modal confirm action performed
    const confirmModalAction = async (data) => {
        setShowConfirmModal(false);
        setConfirmModalData('');
        try {
            const params = `users/?id=${data.id}`;
            const responseData = await CallApi('DELETE','',params);
            if(responseData.status){
                getUsers(searchText,1);
                showToastMessage(`Product Deleted.`,'success')
            }else{
                showToastMessage("user is not deleted",'error')
            }                                                                      
        } catch (error) {
            
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');                      
        }
    }

    return (
        <>
        <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
            <div className="card">
                <div className="card-header">
                    <div className="card-header-form">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Search" fdprocessedid="ijder" onChange={(e) => setSearchText(e.target.value)}/>
                            <div className="input-group-btn">
                                <button className="btn btn-primary" fdprocessedid="1higi">
                                    <FontAwesomeIcon icon={faMagnifyingGlass} style={{ fontWeight: 'bold', fontSize: "13px" }}/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='card-header-action'>
                        <button type="button" className='btn btn-icon icon-left btn-primary' onClick={() => props.addUser(null)}>
                            <FontAwesomeIcon icon={faUserPlus} fontSize={14} className='me-2'/>
                            <span>Add User</span>
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <div className='table-responsive'>
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">User Image</th>
                                <th scope="col">User Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Status</th>
                                <th scope="col">Created On</th>
                                <th scope="col">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                                {users.length > 0 ?
                                users.map((data, index) => {
                                     // Calculate the adjusted index based on the current page
                                    const adjustedIndex = (currentPage - 1) * 10 + index + 1;
                                    return(
                                    <tr key={index}>
                                        <th scope="row">{adjustedIndex}</th>
                                        <td>
                                            <img src={data.user_image ? fileUrl+data.user_image : avatar1}  className='rounded-circle userimg-list me-3'/>
                                        </td>
                                        <td>{data.user_name}</td>
                                        <td>{data.user_email}</td>
                                        <td>
                                            <div className={`badge badge-${data.user_status == "1" ? 'success': 'danger'}`}>{data.user_status == "1" ? 'Active' : 'Not Active'}</div>
                                        </td>
                                        <td>{getDateinFormate(data.created_at)}</td>
                                        <td>
                                            <button className='btn btn-icon icon-left btn-primary me-2' title="Edit User" onClick={() => props.addUser(data.id)}>
                                                <FontAwesomeIcon icon={faUserEdit} fontSize={14}/>
                                            </button>
                                            <button type='button' className='btn btn-icon icon-left btn-danger' title="Delete User" onClick={() => handleDeleteProduct(data.id)}>
                                                <FontAwesomeIcon icon={faUserAltSlash} fontSize={14}/>
                                            </button>
                                        </td>
                                    </tr>)
                                })
                                :
                                <tr>
                                    <td colSpan="7"><center>No Records Found</center></td>
                                </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                
                </div>
                <div className='card-footer text-end'>
                    <nav className='d-inline-block'>
                        <Pagination currentPage={currentPage} lastPage={lastPage} onPageChange={handlePageChange} />
                    </nav>
                </div>
            </div>
            </div>
        </div>
        {/* Confirm modal to confirm action before it perform */}
        <ConfirmModal show={showConfirmModal} data={confirmModalData} onConfirm={(data) => confirmModalAction(data)} setShowConfirmModal={(val) => setShowConfirmModal(val)}/>
        </>
    )
}

export default UsersList