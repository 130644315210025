const user = localStorage.getItem('user');
const token = localStorage.getItem('token');
const initialState = {
    isAuthenticated: !!token,
    user: user,
    token: token
};
  
export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
        // If authentication is successful, update the state with the authenticated user
        return {
            ...state,
            isAuthenticated: true,
            user: action.payload,
            token: action.token
        };
        case 'LOGOUT':
        // Clear the authentication state
        return {
            ...state,
            isAuthenticated: false,
            user: null,
            token: null
        };
        default:
        return state;
    }
};