import React, { lazy, useContext, useEffect, useState } from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate, useLocation} from 'react-router-dom';
import RouteList from './Routes';
import "./assets/styles.css";
import "./assets/app.css";
import { useSelector } from 'react-redux';
import Header from './components/header';
import Login from './screens/Login';
import Sidebar from './components/sidebar';
import { FullscreenContext } from './redux/fullScreenContext';
import ExitFullScreen from './components/ExitFullScreen';
import Toast from './components/Toast';
import { useToast } from './redux/toastContext';

function App() {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const [locationSplit, setLocationSplit] = useState([]);

  const { isFullscreen } = useContext(FullscreenContext);
  const { showToast, toastMessage, toastType, hideToast } = useToast();

  useEffect(() => {
    const location = window.location.pathname;
    const splitPath = location.split('/');
    setLocationSplit(splitPath);
   
  }, [isAuthenticated]);
  
  let locationParent = locationSplit[1];
  let WithoutRouter = ["login"];
    
  return (
    <div className='App'>
      <Toast show={showToast} message={toastMessage} type={toastType} onHide={hideToast}/>
      {isFullscreen && <ExitFullScreen />}
      {isAuthenticated && !WithoutRouter.includes(locationParent) ? (
          <>
            <Header />
            {!isFullscreen && <Sidebar /> }
          </>
        ) : (
          ""
        )}
      <>
      <Routes>
        <Route
            path='/login'
            exact
            element={<Login />}
        />
        {RouteList.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              element={!isAuthenticated ? <Navigate to="/login" replace/> : route.component}
            /> 
        ))}
      </Routes>
      </>
    </div>
  );
}

export default App;
