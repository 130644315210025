import React, { useEffect, useState } from 'react'
import ConfirmModal from '../../components/ConfirmModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass,faPlusSquare, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { CallApi, getDateinFormate } from '../../utils';
import Pagination from '../../components/Pagination';
import Modal from '../../components/Modal';
import AddEditPackages from '../../components/FormModals/AddEditPackages';
import { useToast } from '../../redux/toastContext';

const PackagesList = (props) => {
    const [searchText, setSearchText] = useState('');
    const [packages, setPackages] = useState([]);
    const [packageData, setPackageData] = useState([]);
    const [confirmModalData, setConfirmModalData] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('Add Package');
    const { showToastMessage } = useToast();
    let initialized = false;
    useEffect(() => {
        if (!initialized) { 
            initialized = true
            getPackages(searchText,1)
        }
    }, [searchText])

    // function to get leads data
    const getPackages = async (search,page) => {

        const params = search ? `packages?search=${search}${page > 1 ? `&page=${page}`: ''}`: `packages${page > 1 ? `?page=${page}` : ''}`;
        try {
            const responseData = await CallApi('GET','',params);
            if(responseData.status){
                let packageData = responseData.data.data;
                setPackages(packageData);
                let pagination = responseData.data.pagination;
                setCurrentPage(pagination.currentPage);
                setLastPage(pagination.lastPage);
            }
        } catch (error) {
            
        }
    }

    // Function to handle deleting product
    const handleDeletePackage =  (id) => {
        setShowConfirmModal(true);
        const confirmData = {
            "title":"Confirm Delete",
            "text": "Are you sure you want to delete this package?",
            "id": id,
            "type":"user"
        }
        setConfirmModalData(confirmData);
    }

     // function to handle when the page change
     const handlePageChange = (page) => {
        setCurrentPage(page);
        getPackages(searchText,page)
    }

    // function to handle whene modal confirm action performed
    const confirmModalAction = async (data) => {
        setShowConfirmModal(false);
        setConfirmModalData('');
        try {
            const params = `packages/?id=${data.id}`;
            const responseData = await CallApi('DELETE','',params);
            if(responseData.status){
                showToastMessage(`Product Deleted.`,'success');  
                getPackages(searchText,1);
            }else{
                showToastMessage("package is not deleted",'error')
            }                                                                                                           
        } catch (error) {
            
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');                      
        }
    }

    const addPackage = (id) => {
        if(!id){
            setPackageData([])
        }
        setModalTitle(`${id ? 'Edit' : 'Add'} Package`);
        setShowModal(true);
    }
 
    const editPackage = async(id) => {
        try {
            const params = `packages?id=${id}`;
            const responseData = await CallApi('GET','',params);
            if(responseData.status){
                setPackageData(responseData.data)
                addPackage(id);
            }else{
                showToastMessage("Something went wrong while getting package details",'error');
            }                                                                                             
        } catch (error) {
            
            showToastMessage(`Somethign went wrong. Action not performed.`,'error');                      
        }
    }

    const onAddItem = (data) => {
        setTimeout(() => {
            props.addPackage(data);    
        }, 100);
        
    }

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-form">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search" fdprocessedid="ijder" onChange={(e) => setSearchText(e.target.value)}/>
                                    <div className="input-group-btn">
                                        <button className="btn btn-primary" fdprocessedid="1higi">
                                            <FontAwesomeIcon icon={faMagnifyingGlass} style={{ fontWeight: 'bold', fontSize: "13px" }}/>
                                        </button>
                                    </div>
                                </div>
                        </div>
                        <div className='card-header-action'>
                            <button type="button" className='btn btn-icon icon-left btn-primary' onClick={() => addPackage(null)}>
                                <FontAwesomeIcon icon={faPlusSquare} fontSize={14} className='me-2'/>
                                <span>Add Package</span>
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className='table-responsive'>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Package Name</th>
                                    <th scope="col">Package Description</th>
                                    <th scope="col">Totals</th>
                                    <th scope="col">Created On</th>
                                    <th scope="col">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {packages.length > 0 ?
                                    packages.map((data, index) => {
                                        // Calculate the adjusted index based on the current page
                                        const adjustedIndex = (currentPage - 1) * 10 + index + 1;
                                        return(
                                        <tr key={index}>
                                            <th scope="row">{adjustedIndex}</th>
                                            <td>{data.name}</td>
                                            <td>{data.description}</td>
                                            <td>{data.total}</td>
                                            <td>{getDateinFormate(data.created_at)}</td>
                                            <td>
                                                <button className='btn btn-icon icon-left btn-primary me-2' onClick={() => editPackage(data.id)} title='Edit Package'>
                                                    <FontAwesomeIcon icon={faPen} fontSize={14}/>
                                                </button>
                                                <button type='button' className='btn btn-icon icon-left btn-danger ' onClick={() => handleDeletePackage(data.id)} title='Delete Package'>
                                                    <FontAwesomeIcon icon={faTrash} fontSize={14}/>
                                                </button>
                                            </td>
                                        </tr>)
                                    })
                                    :
                                    <tr>
                                        <td colSpan="10"><center>No Records Found</center></td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    
                    </div>
                    <div className='card-footer text-end'>
                        <nav className='d-inline-block'>
                            <Pagination currentPage={currentPage} lastPage={lastPage} onPageChange={handlePageChange} />
                        </nav>
                    </div>
                </div>
                </div>
            </div>
            {/* Custom modal In which we can set content from here */}
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                title={modalTitle}
            >
                <AddEditPackages modalShow={showModal} onHide={() => setShowModal(false)} package={packageData} onAddItem={(data) => onAddItem(data)}/>
            </Modal>
            {/* Confirm modal to confirm action before it perform */}            
            <ConfirmModal show={showConfirmModal} data={confirmModalData} onConfirm={(data) => confirmModalAction(data)} setShowConfirmModal={(val) => setShowConfirmModal(val)}/>
        </>
    )
}

export default PackagesList