import React, { useContext, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faExpand } from '@fortawesome/free-solid-svg-icons';
import { FullscreenContext } from '../../redux/fullScreenContext';
import HistoryList from './HistoryList';
import HistoryOffers from './HistoryOffers';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../components/Modal';
import CompanyDropdown from '../../components/CompanyDropdown';

const OrderHisory = () => {
    const { state } = useLocation();
    const [content, setContent] = useState('historylist');
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState(false);
    const [data, setData] = useState('');
    const [title, setTitle] = useState(`Orders History ${state.companyName ? ' - '+state.companyName : ''}`);
    const [offerId, SetOfferId] = useState('');
    const [selectedCompany, setSelectedCompany] = useState('');
    const [globalValue, setGlobalValue] = useState('');
    const appElement = document.getElementsByClassName('App')[0];
    const navigate = useNavigate();
    
    const { isFullscreen,toggleFullscreen } = useContext(FullscreenContext);

    const handleContent = (content, data) => {
        if(content == 'offer'){
            sessionStorage.setItem('myData', JSON.stringify(data));
            setShowModal(true);
            setModalTitle("Select Company");
            setGlobalValue({content : content, data: data});
        }else{
            if(data.leadId){
                appElement.setAttribute('data-route','offer');
                navigate('/angebot-anze',{state:data})
            }else{
                setContent(content);
                setData(data);
                if(content == 'offer'){
                    setTitle("Offers");
                }else{
                    setTitle(`Order History ${state.companyName ? ' - '+state.companyName : ''}`);
                }
            }
            
        }
    }

    const backToLeads = () => { 
        sessionStorage.removeItem('myData');    
        sessionStorage.removeItem('company');    
        appElement.removeAttribute('data-route');    
        navigate(-1);
    }

    const modalHideAction = (company) => {
        setShowModal(false)
        setSelectedCompany(company)
        sessionStorage.setItem('company', JSON.stringify(company));
        setContent(globalValue.content);
        setData(globalValue.data);
        if(globalValue.content == 'offer'){
            setTitle("Offers");
        }else{
            setTitle(`Order History ${state.companyName ? ' - '+state.companyName : ''}`);
        }
    }

    useEffect(() => {
        var routeFrom = appElement?.getAttribute('data-route')
        if(routeFrom == 'offer'){
            var data = JSON.parse(sessionStorage.getItem('myData'));
            var company = JSON.parse(sessionStorage.getItem('company'));
            setTitle("Offers");
            setData(data);
            setContent('offer');
            setSelectedCompany(company)
        }
    }, [])
    

    return (
        <>
        <div className="main-content">
            <section className="section">
                {!isFullscreen && (
                    <div className="section-header justify-content-between">
                    <div>
                        <h1 className='me-2'>{title}</h1>
                        {content == 'offer' ?  <h1>(Company : {selectedCompany ? selectedCompany.company_name : ''})</h1> : ''}
                    </div>
                    {content == 'offer' ?
                    <div className='btn-grp'>
                        <button className='btn bt-icon' title="Back" onClick={() => handleContent('historylist','')} >
                            <FontAwesomeIcon icon={faArrowLeft} fontSize={20} className='align-middle'/>
                        </button>
                        <button
                            className="btn bt-icon fullscreenmode-btn"
                            title="fullscreen"
                            onClick={() => toggleFullscreen(true)}
                        >
                            <FontAwesomeIcon
                            icon={faExpand}
                            fontSize={20}
                            className="align-middle"
                            />
                        </button>
                    </div>:
                    <button className='btn bt-icon' title="Back" onClick={() => backToLeads()} >
                        <FontAwesomeIcon icon={faArrowLeft} fontSize={20} className='align-middle'/>
                    </button>
                    }
                    </div>
                )}
                <div className="section-body">
                    {content == 'historylist' ?
                        <HistoryList editOrder={(data) => handleContent('offer',data)}/>
                        : <HistoryOffers onEditOrder={(data) => handleContent('historylist',data)} selectedCompany={selectedCompany} data={data}/>
                    }
                </div>
            </section>
        </div>
        {/* Custom modal In which we can set content from here */}
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            title={modalTitle}
        >
            <CompanyDropdown
                modalShow={showModal}
                selectedCompany={globalValue?.data}
                onHide={(data) => modalHideAction(data)}
            />
        </Modal>
        </>
    )
}

export default OrderHisory