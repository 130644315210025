import { faExpand, faNoteSticky, faPlus, faSuitcase, faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import Card from '../Card';
import { EuroCurrency } from '../../utils';

const Offers = (props) => {
  return (
      <div className="my-3 mx-3">
        <div className="row">
          <div className="col-sm-4">
            <div className="card shadow-sm bg-light ">
              <div className="card-body clmfrst">
                <div className="d-flex clmhdr">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control me-3"
                      placeholder="Search"
                      value={props.searchText}
                      onChange={(event) =>
                        props.setSearchText(event.target.value)
                      }
                    />
                  </div>
                  {props.screen != 'packages' &&
                  <button
                   className="btn btn-outline-primary btn-icon btn-transparent me-2"
                   type='button'
                   onClick={props.showPackages}
                   title='Packages'>
                    <FontAwesomeIcon icon={faSuitcase} />
                  </button>}
                  <button
                    className="btn btn-outline-primary btn-transparent"
                    type="button"
                    onClick={props.handleShowModal}
                    title='Add new card'
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                  
                </div>
                <div className="listItems">
                  {props.cards && props.cards.length > 0 ? (
                    props.cards.map((card) => (
                      <Card
                        card={card}
                        handleMoveProduct={props.handleProductSelect}
                        getProduct={props.getProduct}
                        deleteProduct={props.handleDeleteProduct}
                        key={card.card_id}
                        type="products"
                      />
                    ))
                  ) : (
                    <div className="text-center">
                      <span>No products found</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="card shadow-sm bg-light ">
              <div className="card-body clmfrst">
                <div className="d-flex clmhdr justify-content-center border-bottom">
                  <div className="clm-title">
                    <span>Auswahl</span>
                  </div>
                </div>
                <div className="listItems">
                  {props.selectedCards && props.selectedCards.length > 0 ? (
                    props.selectedCards.map((card) => (
                      <Card
                        card={card}
                        handleMoveProduct={props.handleDeSelectedProducts}
                        key={card.card_id}
                        type="products"
                      />
                    ))
                  ) : (
                    <div className="text-center">
                      <span>No Selected products</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="card shadow-sm bg-light ">
              <div className="card-body clmfrst">
                <div className="d-flex clmhdr justify-content-center border-bottom">
                  <div className="clm-title">
                    <span>Angebot</span>
                  </div>
                </div>
                <div className="listItems last-column">
                  {props.selectedCards && props.selectedCards.map((card) => (
                    <Card
                      card={card}
                      lastColumn={true}
                      key={card.card_id}
                      type="products"
                    />
                  ))}
                  {props.selectedCards && props.selectedCards.length > 0 && props.notes && (
                    <Card
                      card={{note : props.notes}}
                      lastColumn={true}
                      getNote={() => props.getNotes(true)}
                      deleteNote={props.handleDeleteNote}
                      type="notes"
                    />
                  )}
                </div>
                {props.selectedCards && props.selectedCards.length > 0 && (
                  <div>
                    <hr />
                    <div className="bottom-card-box">
                      <div className="d-flex justify-content-between">
                        <div>Nettopreis:</div>
                        <div>{EuroCurrency(props.totalPrice)} &euro;</div>
                      </div>
                      {props.discountValue && props.discountValue != 0.00 ? (
                        <div className="d-flex justify-content-between">
                          <div>
                            {props.discountType == "0"
                              ? `Rabatt ${EuroCurrency(props.discountValue)}%`
                              : "Rabattbetrag"}
                            :
                          </div>
                          <div>{EuroCurrency(props.discountedPrice)} &euro;</div>
                        </div>
                      ): ''}
                      <div className="d-flex justify-content-between">
                        <div>MwSt. {EuroCurrency(19)}%:</div>
                        <div>{EuroCurrency(props.vat)} &euro;</div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>Gesamtpreis:</div>
                        <div>{EuroCurrency(props.total)} &euro;</div>
                      </div>
                      <div className="mt-4 d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={props.submitOffer}
                        >
                          {props.screen == 'packages' ? 'Paket erstellen' : 'Angebot anzeigen'}
                        </button>
                        <div className="btn-groups">
                          <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={() => props.addDiscount(true)}
                            aria-label="discount"
                            title="Add Discount"
                          >
                            <FontAwesomeIcon icon={faTags} />
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => props.getNotes(true)}
                            aria-label="notes"
                            title="Add Notes"
                          >
                            <FontAwesomeIcon icon={faNoteSticky} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Offers